import { useCallback, useContext, useEffect, useState } from 'react';
import { useAuth } from 'modules/customer-authentication/AuthContext';
import useRemoveFavouriteFromClient from 'modules/favourites/hooks/client/useRemoveFavouriteFromClient';
import useRemoveFavouriteFromServer from 'modules/favourites/hooks/server/useRemoveFavouriteFromServer';
import { FavouritesContext } from 'modules/favourites/pages/Favourites/Favourites.state';
import {
  ActionType,
  FavouritesContextInterface,
} from 'modules/favourites/pages/Favourites/Favourites.types';
import { ACTION_TYPES, NOTIFICATION_TYPES } from 'modules/favourites/constants';
import useFavouriteNotification from 'modules/favourites/hooks/useFavouriteNotification';
import { findProductInLists, setUpdateFavourites } from 'modules/favourites/utils/favourites';

const useRemoveFavourites = () => {
  const { isAnonymous } = useAuth();
  const [code, setCode] = useState<string>();

  const { favouritesState, favouritesDispatch } =
    useContext<FavouritesContextInterface>(FavouritesContext);
  const { lists } = favouritesState.favourites;

  const {
    removeFavouriteFromClient,
    dataRemoveFromClient,
    errorRemoveFromClient,
    loadingRemoveFromClient,
  } = useRemoveFavouriteFromClient();

  const {
    removeFavouriteFromServer,
    dataRemoveFromServer,
    errorRemoveFromServer,
    loadingRemoveFromServer,
  } = useRemoveFavouriteFromServer();

  const { getNotifications } = useFavouriteNotification();

  const errors = isAnonymous ? errorRemoveFromClient : errorRemoveFromServer;
  const loading = isAnonymous ? loadingRemoveFromClient : loadingRemoveFromServer;
  const data = isAnonymous ? dataRemoveFromClient : dataRemoveFromServer;

  useEffect(() => {
    if (!loading && !errors && (data?.getProducts || data?.removeFavouriteItem)) {
      let listName = null;
      let listId = null;
      if (isAnonymous && data?.getProducts?.products) {
        favouritesDispatch({
          type: ActionType.UPDATE_FAVOURITES,
          payload: {
            products: data.getProducts.products || [],
            lists: [],
          },
        });
      }

      if (!isAnonymous && data?.removeFavouriteItem) {
        listName = !data.removeFavouriteItem.defaultList ? data.removeFavouriteItem.name : null;
        listId = data.removeFavouriteItem.id;
        favouritesDispatch({
          type: ActionType.UPDATE_LIST,
          payload: data.removeFavouriteItem,
        });
      }

      getNotifications({
        type: NOTIFICATION_TYPES.SUCCESS,
        action: ACTION_TYPES.REMOVE_ENTRY,
        listName,
        listId,
      });
    }
  }, [data, errors, favouritesDispatch, getNotifications, isAnonymous, loading]);

  useEffect(() => {
    if (!loading && errors) {
      let listName = null;
      let id = null;
      if (!isAnonymous && code) {
        const { listId, name } = findProductInLists(lists, code) || { listId: null, name: null };
        listName = name;
        id = listId;
      }

      getNotifications({
        type: NOTIFICATION_TYPES.ERROR,
        action: ACTION_TYPES.REMOVE_ENTRY,
        listName,
        listId: id,
      });
    }
  }, [errors, getNotifications, loading, isAnonymous, code, lists]);

  const removeFavourite = useCallback(
    async (code: string) => {
      setCode(code);
      isAnonymous ? await removeFavouriteFromClient(code) : await removeFavouriteFromServer(code);
      setUpdateFavourites();
    },
    [isAnonymous, removeFavouriteFromClient, removeFavouriteFromServer],
  );

  return {
    removeFavourite,
    data,
    loading,
    errors,
  };
};

export default useRemoveFavourites;
