import { useCallback } from 'react';
import { DocumentNode, LazyQueryHookOptions, useLazyQuery } from '@apollo/client';
import { useAuth } from 'modules/customer-authentication/AuthContext';

const useLazyQueryAuth = <T>(query: DocumentNode, options: LazyQueryHookOptions) => {
  const { isAnonymous } = useAuth();
  const [load, result] = useLazyQuery<T>(query, options);
  const loadOverride = useCallback(() => {
    if (!isAnonymous) {
      load();
    }
  }, [isAnonymous, load]);
  return { load: loadOverride, result };
};

export default useLazyQueryAuth;
