import { useMemo } from 'react';
import useFeatureFlags from 'hooks/useFeatureFlags';

/**
 * Returns a boolean which describes whether the given FeatureFlag(s) are enabled.
 * @param {String | [String]} featureFlag The FeatureFlag(s).
 * @param {*} options Options with which the return value can be customized.
 *  - fallbackValue: The fallback value which gets returned in case FeatureFlags aren't fully loaded yet. (boolean)
 *  - condition: The condition which decides what this hooks returns. ('some' / 'every')
 *  'some' : If at least one FeatureFlag is true, true will be returned, false otherwise.
 *  'every': If all FeatureFlags are true, true will be returned, false otherwise.
 * @returns True if the given condition is fulfilled, false otherwise.
 */
interface UseFeatureFlagsEnabledOptions {
  fallbackValue?: boolean | null;
  condition?: 'some' | 'every';
}

const useFeatureFlagsEnabled = (
  featureFlag: string | string[],
  { fallbackValue = false, condition = 'every' }: UseFeatureFlagsEnabledOptions = {},
) => {
  const features = useFeatureFlags(featureFlag);

  return useMemo(
    () => (features.length ? features[condition](Boolean) : fallbackValue),
    [fallbackValue, features, condition],
  );
};

export default useFeatureFlagsEnabled;
