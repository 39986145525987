import { energyEfficiencyData, mediaData } from 'graphql-fragments/product';

const price = `currencyIso
value
`;

export const address = ` addition1
companyName
country {
  isocode
  name
}
dateOfBirth
defaultAddress
email
fax
firstName
floor
formattedAddress
gender
id
lastName
lift
line1
line2
phone
phone2
postalCode
pricepassNr
region{
  countryIso
  isocode
  isocodeShort
  name
}
restType
salesTaxNumbers {
  number
  rank
}
shippingAddress
streetname
streetnumber
title
titleCode
town
uid
visibleInAddressBook
`;

export const breadcrumbs = `itemCode
seoUrl
name
type
`;

export const coreFacet = `
  __typename
  filterId
  id
  title
  rid
`;

export const coreFacetValues = `
  __typename
  count
  id
  title
  valueId
`;

export const dimensions = `
dimensions {
  type
  value
}
`;

export const promotionResult = `consumedEntries {
  adjustedUnitPrice
  orderEntryNumber
  reductionValue
  quantity
}
description
id
name
productPriceReduction
promotion {
  code
}
promotionDiscount {
  ${price}
}
valueDisplayed
`;

export const reducedPriceData = `
    currentPrice {
      ${price}
    }
    oldPrice {
      value
    }
    currentNetPrice {
      value
    }
    oldNetPrice {
      value
    }
    savedPercent
    productUnitDiffersToPriceUnit
`;

export const services = `services{
  code
  consumed
  price
  restType
  totalPrice
  type
}`;

export const commonPriceData = `
    ${reducedPriceData}
    deliveryCost {
      value
    }
    offerAttributes {
      offerId
    }
    postCost {
      value
    }
    pricePerUnit {
      value
    }
    shippingCost {
      value
    }
    hideUvp
    sellingUnit
    sellingAmount
    productUnitDiffersToPriceUnit
`;

const basicProduct = `baseProduct
deliveryData {
  deliveryCostsCharged
  assembly
  bulkyProduct
  customDeliveryType
  defaultDeliveryTimeRetrievalEnabled
  delivery
  deliveryCostsCharged
  deliveryIndicator
  deliveryInformationForCustomers {
    name
    value
    valueId
  }
  fastDeliveryShown
}
characteristicData {
  colorMapping {
    targetColor
    webColor
  }
  detailAttributes {
    attributes {
      code
      name
      value
    }
  }
}
breadcrumbs {
  ${breadcrumbs}
}
code
mediaData {
  ${mediaData}
}
energyEfficiencyData {
  ${energyEfficiencyData}
}
eyecatchers {
  code
  name
  style
}
style {
  code
  name
}
brands {
  code
  image {
    altText
    filename
    pictureHeight
    pictureWidth
  }
  name
  url
  urlCode
}
attributes {
    colorMapping {
      code
      targetColor
      webColor
    }
    dimensions {
      ${dimensions}
      strRepresentation
      strRepresentationKey
    }
    material
}
name
picture {
  altText
  cdnFilename
}
price {
  specialOfferTypeData {
    numberOfAsterisks
  }
  value
}
priceData {
  ${commonPriceData}
  currentPrice {
    endTime
     specialOfferTypeData {
      numberOfAsterisks
    }
  }
}
reservationData {
  maxReservableAmount
  reservable
}
color
url
`;

const configuredProduct = `
    configuredProduct
    configuredProductData {
    attachments {
      altText
      code
      url
    }
    images {
      altText
      url
    }
    configuredId
    productNumber
    description
    name
    restType
    summary
  }`;

const payment = `paymentAddress {
  addition1
  addressId
  companyName
  country {
    isocode
    name
  }
  countryIsoCode
  email
  firstName
  floor
  gender
  lastName
  lift
  line1
  line2
  phone
  phoneNumberIsoCode
  phoneNumberNational
  postalCode
  pricepassNr
  regionIsoCode
  region {
    isocode
    name
  }
  salesTaxNumbers {
    number
  }
  streetname
  streetnumber
  titleCode
  town
}
deliveryCost {
  value
}
deliveryCostAssembling {
  value
}
deliveryCostWithoutSpecial {
  value
}
`;

const deliveryModes = `  deliveryModes {
  assemblyCost
  code
  customShippingType
  deliveryCost {
    value
  }
  deliveryCostSpecial {
    value
  }
  deliveryCostSpecialType
  deliveryTime
  description
  deliveryTimeText
  isFreeDeliveryCost
  name
  selfServiceStores {
    address {
      formattedAddress
      line1
      line2
      phone
      postalCode
      region {
        countryIso
        isocode
        name
      }
      streetname
      streetnumber
      town
    }
    code
    openingHoursText
    name
    geoPoint {
      latitude
      longitude
    }
    seoData {
      canonicalUrl
      title
      description
      noIndex
      noFollow
      url
    }
  }
}
deliveryMode {
  code
  description
  name
  deliveryTimeText
  customShippingType
}
carrier
`;

const messages = `messages{
  arguments {
    type
    value
  }
  message
}`;

export const paymentMode = `
  active
  code
  paymentModeId
  description
  logo{
    altText
    cdnFilename
    fileExtension
    format
    mediaProvider
    mime
    pictureHeight
    pictureWidth
    realFilename
    seoText
    url
  }
  mobileDescription
  name
  paymentCost {
    currencyIso
    value
  }
  pgProvider
  pspGroupedCode
  redirectHint
  redirectMessage
  redirectPageHeader
`;

const delivery = `deliveryAddress {
  addition1
  addressId
  email
  firstName
  floor
  gender
  lastName
  lift
  line1
  line2
  phone
  phoneNumberIsoCode
  phoneNumberNational
  postalCode
  pricepassNr
  regionIsoCode
  region {
    isocode
    name
  }
  streetname
  streetnumber
  titleCode
  town
}
paymentMode {
  ${paymentMode}
}
paymentModes {
  active
  additionalDetails
  code
  description
  logo{
    altText
    cdnFilename
    fileExtension
    format
    mediaProvider
    mime
    pictureHeight
    pictureWidth
    realFilename
    seoText
    url
  }
  mobileDescription
  name
  paymentCost {
    currencyIso
    value
  }
  pgProvider
  pspGroupedCode
  redirectHint
  redirectMessage
  redirectPageHeader
}
`;

const pointOfService = `pointOfService {
  name
  code
  address {
    formattedAddress
  }
  description
}
`;

export const favouriteProduct = `
  type
  baseProduct
  additionalProductExpenses {
    price
    type
  }
  attributes {
    colorMapping {
      code
      targetColor
      webColor
    }
    dimensions {
      ${dimensions}
      strRepresentation
      strRepresentationKey
    }
    material
  }
  mediaData {
    ${mediaData}
  }
  characteristicData {
    colorMapping {
      targetColor
      webColor
    }
    detailAttributes {
      name
      attributes {
        code
        name
        value
      }
    }
  }
  brands {
    code
    image {
      altText
      filename
      pictureHeight
      pictureWidth
    }
    name
    url
    urlCode
  }
  code
  energyEfficiencyData {
    ${energyEfficiencyData}
  }
  eyecatchers {
    code
    name
  }
  color
  name
  picture {
    altText
    cdnFilename
  }
  orderData {
    buyable
    maxOrderableAmount
    sellable
  }
  price {
    value
    specialOfferTypeData {
      numberOfAsterisks
    }
  }
  priceData {
    ${commonPriceData}
    currentPrice {
      specialOfferTypeData {
        numberOfAsterisks
      }
    }
  }
  style {
    code
    name
  }
  statisticKPI
  url`;

export const favourite = `id
  name
  entries {
    id
    item {
      code
      entryNumber
      product {
        ${favouriteProduct}
      }
      quantity
      type
    }
  }
`;

export const cart = `code
additionalExpenseCosts {
  type
  value
}
appliedOrderPromotions {
  ${promotionResult}
  removable
  usedCouponCodes
}
appliedProductPromotions {
  ${promotionResult}
  removable
  usedCouponCodes
}
appliedVoucherDataList {
  appliedValue {
    ${price}
  }
  applied
  code
  currency {
    name
  }
  description
  freeShipping
  overflowAmount
  promotionCode
  name
  value
  violationMessage
  restrictions {
    type
    ... on ProductQuantityRestriction {
      unit
      quantity
    }
    ... on ProductGroupRestriction {
      categoryMappingCodesList
      included
    }
    ... on ProductRestriction {
      products {
        code
      }
    }
    ... on ProductBrandRestriction {
      brands {
        code
      }
    }
    ... on ProductConfiguredRestriction {
      configuredOnly
    }
    ... on StatisticIdentifierRestriction {
      statisticIdentifier
    }
    ... on ProductEyecatcherRestriction {
      eyecatchers {
        code
        style
      }
    }
    ... on ProductCategoryRestriction {
      categories {
        code
      }
    }
  }
  voucherType
}
code
currency {
  isocode
}
${delivery}
deliveryAddressRequired
entries {
  ${pointOfService}
  ${deliveryModes}
  additionalExpenses {
    price
    restType
    totalPrice
    type
  }
  appliedProductPromotionNames
  deliveryTime
  entryNumber
  freeGiftProductVoucher
  oldTotalPrice {
    ${price}
  }
  product {
    ${basicProduct}
    ${configuredProduct}
    visible
    statisticKPI
    urlFromSeoData
  }
  quantity
  totalPrice {
    ${price}
  }
  shopInformation {
    isExternal
    shopId
    shopName
  }
  selectedDeliveryMode
  selectedQuantity
  ${services}
  unitBasedCalculation {
    deliveryAmount
    oldPriceTotal {
        currencyIso
        formattedValue
        priceType
        value
    }
    packageAmount
    selectedDeliveryAmount
    amount
  }
  updateable
}
guid
${messages}
${payment}
orderType
paymentValidationSuccess
pickupStationAddress {
  ${address}
}
potentialOrderPromotions {
  addedPotentialPromotionInfo {
    mainPromotionCode
    mainPromotionDescription
    mainPromotionName
    url
  }
  ${promotionResult}
}
potentialProductPromotions {
  addedPotentialPromotionInfo {
    mainPromotionCode
    mainPromotionDescription
    mainPromotionName
    url
  }
  ${promotionResult}
}
serviceCosts {
    type
    value
}
orderType
postalCode
saveAddresses
showMergedCart
subTotal {
  ${price}
}
totalItems
totalPrice {
  ${price}
}
totalPriceWithTax {
  ${price}
}
totalPriceWithoutCoupons {
  ${price}
}
totalTax {
  ${price}
}
town
userComment
voucherSum {
  ${price}
}`;

export const order = `code
appliedOrderPromotions {
  ${promotionResult}
  removable
  usedCouponCodes
}
appliedProductPromotions {
  ${promotionResult}
  removable
  usedCouponCodes
}
appliedVoucherDataList {
  appliedValue {
    ${price}
  }
  applied
  code
  currency {
    name
  }
  description
  freeShipping
  overflowAmount
  promotionCode
  name
  value
  violationMessage
  restrictions {
    type
    ... on ProductQuantityRestriction {
      unit
      quantity
    }
    ... on ProductGroupRestriction {
      categoryMappingCodesList
      included
    }
    ... on ProductRestriction {
      products {
        code
      }
    }
    ... on ProductBrandRestriction {
      brands {
        code
      }
    }
    ... on ProductConfiguredRestriction {
      configuredOnly
    }
    ... on StatisticIdentifierRestriction {
      statisticIdentifier
    }
    ... on ProductEyecatcherRestriction {
      eyecatchers {
        code
      }
    }
    ... on ProductCategoryRestriction {
      categories {
        code
      }
    }
  }
  voucherType
}
code
currency {
  isocode
}
${delivery}
entries {
  ${pointOfService}
  ${deliveryModes}
  deliveryTime
  customShippingType
  entryNumber
  product {
    ${basicProduct}
  }
  quantity
  totalPrice {
    ${price}
  }
  selectedDeliveryMode
  selectedQuantity
  ${services}
  shopInformation {
    isExternal
    shopId
    shopName
  }
  unitBasedCalculation {
    deliveryAmount
    oldPriceTotal {
        currencyIso
        formattedValue
        priceType
        value
    }
    packageAmount
    selectedDeliveryAmount
    amount
  }
  updateable
}
guid
${messages}
${payment}
orderType
saveAddresses
subOrders {
  code
  selectedStore {
    code
    name
    seoData {
      url
    }
  }
  totalPriceWithTax {
    currencyIso
    value
  }
}
subTotal {
  ${price}
}
totalItems
totalPrice {
  ${price}
}
totalPriceWithTax {
  ${price}
}
totalTax {
  ${price}
}
totalPriceWithoutCoupons {
  ${price}
}
totalNetPriceWithoutCoupons {
  ${price}
}
voucherSum {
  ${price}
}`;

export const productConfiguratorData = `
    openType
    restType
    url
`;

export const customerDetails = `
   salutation
   title
   firstname
   lastname
   birthdate
   birthdays
   phone
   created
`;

export const addressData = `
   id
   maddress
   saddress
   baddress
   gender
   titleCode
   firstName
   lastName
   email
   phone
   companyName
   attn
   salesTaxNumbers {
      id
      number
      rank
      created
   }
   streetname
   streetnumber
   floor
   lift
   postalCode
   town
   country
   addition1
   created
`;

export const preferences = `
valid
data
error`;

export const productCampaignAndListingData = `
availabilityStatus
averageRating
name
numberOfReviews
url
code
firstItemOfPage
hasRatings
legalInformationShown
color
eyecatcherABIsShown
freeDelivery
adInfo {
  adId
}
attributes {
  colorMapping {
    code
    targetColor
    webColor
  }
  dimensions {
    ${dimensions}
    strRepresentation
    strRepresentationKey
  }
  material
}
priceData {
  ${commonPriceData}
  currentPrice {
    specialOfferTypeData {
      numberOfAsterisks
    }
  }
}
configurable
energyEfficiencyData {
  ${energyEfficiencyData}
}
mediaData {
  presentation {
    altText
    cdnFilename
    realFilename
    seoText
    fileType
  }
  datasheets {
    url
    realFilename
    documentType
  }
}
brands {
  name
}
eyecatchers {
  code
  style
}
style {
  code
  name
}
statisticKPI
type
variantContainerData {
  variantHighlightType
  variantGroups {
    count
    name
    variantValues {
      enabled
      product {
        availabilityStatus
        code
        color
        name
        url
        priceData {
          ${commonPriceData}
          currentPrice {
            specialOfferTypeData {
              numberOfAsterisks
            }
          }
        }
      }
      productPicture {
          altText
          cdnFilename
          hashCode
          fileType
          filename
          fileExtension
      }
      restType
      selected
      value
    }
  }
}
orderData {
  buyable
}
hotdealData {
  assignedStore {
    name
  }
}`;
