import { gql } from '@apollo/client';
import { customerDetails, preferences } from 'graphql-fragments/index';

export const UPDATE_CUSTOMER_DATA = gql`
mutation updateCustomerData($customerData: CustomerDataInput!) {
    updateCustomerData(customerData: $customerData) {
       ${customerDetails}
    }
}
`;

export const SAVE_PREFERENCES_MUTATION = gql`
  mutation savePreferences($data: JSON!) {
    savePreferences(data: $data) {
      ${preferences}
    }
  }
`;
