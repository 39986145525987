import React, {
  Dispatch,
  createContext,
  MutableRefObject,
  RefObject,
  useContext,
  SetStateAction,
} from 'react';
import {
  Product,
  RatingStatistic,
  Review,
  DimensionType,
  ReviewPagination,
} from 'graphql-types/generated/types-generated';

export type ProductContextType = Omit<Product, 'code'> & { code: string };

export interface ProductContextProps {
  loading: boolean;
  product: ProductContextType;
  isLargerThanMd?: boolean;
  /** the reference of the element, after which the sticky bar appears */
  stickyBarTriggerRef?: RefObject<HTMLDivElement>;
  ratings?: {
    dimensions?: RatingStatistic[] | null;
    dimensionTypes?: DimensionType[] | null;
    pagination?: ReviewPagination | null;
    ratingsRef?: MutableRefObject<HTMLDivElement | null>;
    ratingsFilterButtonRef?: MutableRefObject<HTMLButtonElement | null>;
    ratingStatistic?: RatingStatistic | null;
    reviews?: Review[] | null;
    state?: {
      amount?: number | null;
      chosenStars?: number | null;
      currentPage?: number | null;
    };
    stateSetters?: {
      setAmount?: Dispatch<SetStateAction<number | null>>;
      setChosenStars?: Dispatch<SetStateAction<number | null>>;
      setCurrentPage?: Dispatch<SetStateAction<number | null>>;
    };
  };
}

export const DefaultProduct: Omit<Product, 'code'> & { code: string } = { code: '' };

export const DefaultProductContext = {
  loading: true,
  product: DefaultProduct,
  isLargerThanMd: true,
  stickyBarTriggerRef: { current: null },
  ratings: {
    dimensions: [],
    dimensionTypes: null,
    pagination: null,
    ratingsRef: { current: null },
    ratingsFilterButtonRef: { current: null },
    ratingStatistic: {
      ratingValue: null,
      ratingCount: null,
      ratingStatisticPerRatingValue: null,
    },
    reviews: null,
    state: {
      amount: 0,
      chosenStars: 0,
      currentPage: 0,
    },
    stateSetters: {
      setAmount: () => {},
      setChosenStars: () => {},
      setCurrentPage: () => {},
    },
  },
};

const ProductContext = createContext<ProductContextProps>(DefaultProductContext);
export const useProductContext = () => useContext(ProductContext);
export default ProductContext;

// used in tests
export const withProductContext = (component: JSX.Element, context = DefaultProductContext) => (
  <ProductContext.Provider value={context}>{component}</ProductContext.Provider>
);
