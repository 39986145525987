import { useEffect } from 'react';
import PageLoadEmitter from 'utils/PageLoadEmitter';

const useOnPageLoad = (onLoad) => {
  if (!CONFIG.IS_SSR) {
    PageLoadEmitter.addPromise(Promise.resolve());
  }
  useEffect(() => {
    PageLoadEmitter.addEventListener('load', onLoad);

    return () => PageLoadEmitter.removeEventListener('load', onLoad);
  }, [onLoad]);
};

export default useOnPageLoad;
