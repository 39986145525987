import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  createContext,
  useMemo,
  useReducer,
  useState,
} from 'react';
import { useAuth } from 'modules/customer-authentication/AuthContext';
import { isArrayEmpty } from '@xxxlgroup/hydra-utils/common';
import { local } from '@xxxlgroup/hydra-utils/storage';
import { favouritesReducer } from 'modules/favourites/pages/Favourites/Favourites.reducer';
import {
  ActionType,
  FavouritesStateType,
  Action,
  FavouritesContextInterface,
} from 'modules/favourites/pages/Favourites/Favourites.types';
import {
  removeFavouritesFromLocal,
  getProductsToAdd,
  getSessionId,
  updateClientFavouriteFromCodes,
} from 'modules/favourites/utils/favourites';
import useAddFavouriteToServer from 'modules/favourites/hooks/server/useAddFavouriteToServer';
import useGetFavourites from 'modules/favourites/hooks/useGetFavourites';
import useIsMobileApp from 'hooks/useIsMobileApp';
import { FavouritesSource } from 'utils/mobileApp.variables';

export const initialState = {
  favourites: {
    products: [],
    lists: [],
    loading: false,
    errors: undefined,
  },
  isComingFromAccount: false,
  isMobileApp: false,
};

export const FavouritesContext = createContext<FavouritesContextInterface>({
  favouritesState: initialState,
  favouritesDispatch: () => null,
});

export const useFavouritesContext = () => useContext(FavouritesContext);

/**
 * Initialize State with empty favourites
 */

export interface FavouritesProviderProps {
  initState: FavouritesStateType;
}

export const FavouritesProvider = (props: PropsWithChildren<FavouritesProviderProps>) => {
  const { initState = initialState, children } = props;
  const { isAnonymous } = useAuth();
  const isMobileApp = useIsMobileApp();

  const [favouritesState, favouritesDispatch] = useReducer<
    (arg1: FavouritesStateType, actions: Action) => any
  >(favouritesReducer, initState);

  const { getFavourites, data, loading, errors } = useGetFavourites();
  const { addFavouriteToServer, loadingAddToServer } = useAddFavouriteToServer();
  const [isMerged, setIsMerged] = useState(false);
  const [isGetData, setIsGetData] = useState(false);
  const [sessionId, setSessionId] = useState('');
  const mergeFavourites = useCallback(async () => {
    const productsToAdd = getProductsToAdd();
    if (!isArrayEmpty(productsToAdd)) {
      await addFavouriteToServer(productsToAdd);
    }
    removeFavouritesFromLocal();
    setIsMerged(true);
  }, [addFavouriteToServer]);

  const handleAppNotification = useCallback(
    (event: any) => {
      const { data } = event.detail;
      if (data?.products) {
        updateClientFavouriteFromCodes(data.products);
        getFavourites();
        setIsGetData(true);
      }
    },
    [getFavourites],
  );

  useEffect(() => {
    setSessionId(getSessionId());
  }, [sessionId]);

  useEffect(() => {
    if (isMobileApp) {
      favouritesDispatch({
        type: ActionType.UPDATE_IS_MOBILE_APP,
        payload: isMobileApp,
      });
      window.addEventListener(FavouritesSource.FAVOURITES_FROM_APP_EVENT, handleAppNotification);
    }

    return () => {
      if (isMobileApp) {
        window.removeEventListener(
          FavouritesSource.FAVOURITES_FROM_APP_EVENT,
          handleAppNotification,
        );
      }
    };
  }, [handleAppNotification, isMobileApp]);

  useEffect(() => {
    (async () => {
      await getFavourites();
      setIsGetData(true);
    })();
  }, [getFavourites, isAnonymous]);

  useEffect(() => {
    !isAnonymous && mergeFavourites();
  }, [isAnonymous, mergeFavourites]);

  const handleStorageChange = useCallback(
    (event: StorageEvent) => {
      if (event.key === 'favouritesUpdated') {
        const updatedSessionId = local.getItem('favouritesUpdated');
        if (updatedSessionId && updatedSessionId !== sessionId) {
          getFavourites();
          setIsGetData(true);
          local.removeItem('favouritesUpdated');
        }
      }
    },
    [getFavourites, sessionId],
  );

  useEffect(() => {
    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [getFavourites, handleStorageChange, sessionId]);

  useEffect(() => {
    if (isGetData) {
      favouritesDispatch({
        type: ActionType.UPDATE_FAVOURITES,
        payload: { products: data?.products || [], lists: data?.favourites || [], loading, errors },
      });
      if (!loading) {
        setIsGetData(false);
      }
    }
  }, [data, errors, isGetData, isMerged, loading]);

  useEffect(() => {
    if (isMerged && !loadingAddToServer && !loading) {
      (async () => {
        await getFavourites();
        setIsGetData(true);
        setIsMerged(false);
      })();
    }
  }, [getFavourites, isMerged, loading, loadingAddToServer]);

  const contextValue: FavouritesContextInterface = useMemo(
    () => ({
      favouritesState,
      favouritesDispatch,
    }),
    [favouritesState, favouritesDispatch],
  );
  return <FavouritesContext.Provider value={contextValue}>{children}</FavouritesContext.Provider>;
};
