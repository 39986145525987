import { useCallback, useContext, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { CREATE_FAVOURITE_LIST_MUTATION } from 'modules/favourites/queries/Favourites.mutation';
import {
  ActionType,
  FavouritesContextInterface,
  ProductItemType,
} from 'modules/favourites/pages/Favourites/Favourites.types';
import { FavouritesContext } from 'modules/favourites/pages/Favourites/Favourites.state';
import { setUpdateFavourites } from 'modules/favourites/utils/favourites';

interface CreateFavouriteListProps {
  name: string | null;
  item: ProductItemType | null;
}

const useCreateFavouriteList = () => {
  const [createFavouriteListMutation, { data, error, loading }] = useMutation(
    CREATE_FAVOURITE_LIST_MUTATION,
  );

  const { favouritesDispatch } = useContext<FavouritesContextInterface>(FavouritesContext);

  const createFavouriteList = useCallback(
    ({ name, item }: CreateFavouriteListProps) => {
      createFavouriteListMutation({
        fetchPolicy: 'no-cache',
        variables: {
          favouriteData: { item, name },
        },
      });
    },
    [createFavouriteListMutation],
  );

  useEffect(() => {
    if (!loading && !error && data?.createFavouriteList) {
      favouritesDispatch({
        type: ActionType.UPDATE_FAVOURITES,
        payload: {
          products: [],
          lists: data.createFavouriteList.favourites,
        },
      });
      setUpdateFavourites();
    }
  }, [data, error, favouritesDispatch, loading]);

  return {
    createFavouriteList,
    data,
    error,
    loading,
  };
};

export default useCreateFavouriteList;
