import { gql } from '@apollo/client';
import {
  favouriteProduct,
  favouriteWithDefaultList as favourite,
} from 'graphql-fragments/fragments';

export const GET_PRODUCTS_QUERY = gql`
  ${favouriteProduct}
  query favouriteProducts($codes: String!) {
    getProducts(codes: $codes) {
      products {
        ...favouriteProduct
      }
    }
  }
`;

export const GET_FAVOURITE_LISTS_QUERY = gql`
  ${favourite}
  query getFavouriteLists {
    getFavouriteLists {
      favourites {
        ...favouriteWithDefault
      }
    }
  }
`;
