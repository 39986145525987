import { useEffect } from 'react';
import { injectScript } from 'utils/url';
import useFeatureFlagsEnabled from 'hooks/useFeatureFlagsEnabled';
import useConsent from 'hooks/useConsent';

const SERCH_COLLECTOR_VENDOR = 'cstm_searchcollector';

export const useSearchCollector = () => {
  const isSearchCollectorEnabled = useFeatureFlagsEnabled('poseidon.search.collector.scriptid');
  const isSearchCollectorVendorEnabled = useConsent(SERCH_COLLECTOR_VENDOR);

  useEffect(() => {
    if (isSearchCollectorEnabled && isSearchCollectorVendorEnabled) {
      injectScript({ src: 'https://c.searchhub.io/M4r1M6d80' });
    }
  }, [isSearchCollectorEnabled, isSearchCollectorVendorEnabled]);
};
