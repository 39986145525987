import { useContext, createContext } from 'react';

interface CustomerDataTypes {
  title?: number;
  salutation?: number;
  firstname?: string | null;
  lastname?: string | null;
  birthdate?: string | null;
  birthdays?: number;
  phone?: string | null;
  email?: string | null;
  created?: string;
  profilename?: string;
}

interface UserDataContextProps {
  customerData?: CustomerDataTypes;
  setCustomerData?: (customerData: CustomerDataTypes) => void;
}

const initialContext: UserDataContextProps = {};

export const UserDataContext = createContext(initialContext);

export const useUserDataContext = () => useContext(UserDataContext);
