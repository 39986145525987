import { useCallback, useContext, useEffect, useState } from 'react';
import { useAuth } from 'modules/customer-authentication/AuthContext';
import useAddFavouriteToClient from 'modules/favourites/hooks/client/useAddFavouriteToClient';
import useAddFavouriteToServer from 'modules/favourites/hooks/server/useAddFavouriteToServer';
import { FavouritesContext } from 'modules/favourites/pages/Favourites/Favourites.state';
import {
  ProductItemType,
  ActionType,
  FavouritesContextInterface,
} from 'modules/favourites/pages/Favourites/Favourites.types';
import { ACTION_TYPES, NOTIFICATION_TYPES } from 'modules/favourites/constants';
import useFavouriteNotification from 'modules/favourites/hooks/useFavouriteNotification';
import { setUpdateFavourites } from 'modules/favourites/utils/favourites';

const useAddFavourites = () => {
  const { isAnonymous } = useAuth();

  const [isCalled, setIsCalled] = useState(false);
  const [listId, setListId] = useState<string | undefined>(undefined);
  const { favouritesState, favouritesDispatch } =
    useContext<FavouritesContextInterface>(FavouritesContext);
  const { lists } = favouritesState.favourites;
  const { addFavouriteToClient, dataAddToClient, errorAddToClient, loadingAddToClient } =
    useAddFavouriteToClient();
  const { addFavouriteToServer, dataAddToServer, errorAddToServer, loadingAddToServer } =
    useAddFavouriteToServer();
  const errors = isAnonymous ? errorAddToClient : errorAddToServer;
  const loading = isAnonymous ? loadingAddToClient : loadingAddToServer;
  const data = isAnonymous ? dataAddToClient : dataAddToServer;

  const { getNotifications } = useFavouriteNotification();

  useEffect(() => {
    if (isCalled && !loading && !errors && (data?.getProducts || data?.addFavouriteItems)) {
      let listName = null;
      let listId = null;
      if (isAnonymous && data?.getProducts?.products) {
        favouritesDispatch({
          type: ActionType.UPDATE_FAVOURITES,
          payload: {
            products: data.getProducts.products || [],
            lists: [],
          },
        });
      }
      if (!isAnonymous && data?.addFavouriteItems) {
        listName = !data.addFavouriteItems.defaultList ? data.addFavouriteItems.name : null;
        listId = data.addFavouriteItems.id;
        favouritesDispatch({
          type: ActionType.UPDATE_LIST,
          payload: data.addFavouriteItems,
        });
      }

      getNotifications({
        type: NOTIFICATION_TYPES.SUCCESS,
        action: ACTION_TYPES.ADD_ENTRY,
        listName,
        listId,
      });

      setIsCalled(false);
    }
  }, [isCalled, data, errors, favouritesDispatch, isAnonymous, loading, getNotifications]);

  useEffect(() => {
    if (isCalled && !loading && errors) {
      let listName = null;
      if (!isAnonymous && listId) {
        const currentList = lists.find((list) => list.id === listId);
        if (currentList && !currentList.defaultList) {
          listName = currentList.name;
        }
      }

      getNotifications({
        type: NOTIFICATION_TYPES.ERROR,
        action: ACTION_TYPES.ADD_ENTRY,
        listId,
        listName,
      });
      setIsCalled(false);
    }
  }, [errors, getNotifications, isAnonymous, isCalled, listId, lists, loading]);

  const addFavourite = useCallback(
    async (products: ProductItemType[], favouriteListId: string | undefined = 'default') => {
      setIsCalled(true);
      if (favouriteListId !== 'default') {
        setListId(favouriteListId);
      }

      isAnonymous
        ? await addFavouriteToClient(products)
        : await addFavouriteToServer(products, favouriteListId);

      setUpdateFavourites();
    },
    [isAnonymous, addFavouriteToClient, addFavouriteToServer],
  );

  return {
    addFavourite,
    data,
    loading,
    errors,
    isAnonymous,
  };
};

export default useAddFavourites;
