import React, { useState, useEffect, useCallback } from 'react';
import { Link as HydraLink } from '@xxxlgroup/hydra-ui-components';
import { plus } from '@xxxlgroup/hydra-icons';
import useMessage from 'components/Message/useMessage';
import {
  LOCALIZATION,
  LIST_MODAL_TYPES,
  NOTIFICATION_TYPES,
  ACTION_TYPES,
} from 'modules/favourites/constants';

import track from 'react-tracking';
import { useTracking } from 'utils/tracking/hooks';
import { tagComponent } from 'utils/tracking/tracking';

import { ProductItemType } from 'modules/favourites/pages/Favourites/Favourites.types';
import ListModal from 'modules/favourites/components/ListModal';
import useCreateFavouriteList from 'modules/favourites/hooks/useCreateFavouriteList';
import useFavouriteNotification from 'modules/favourites/hooks/useFavouriteNotification';

import styles from 'modules/favourites/components/CreateListLink/CreateListLink.scss';

interface CreateListLinkProps {
  handlePostSubmit?: () => void;
  selectedProduct?: ProductItemType;
}

const CreateListLink = (props: CreateListLinkProps) => {
  const { selectedProduct, handlePostSubmit } = props;
  const tracking = useTracking(props, 'CreateListLink');

  const [isModalOpen, setIsModalOpen] = useState(false);

  const { getNotifications } = useFavouriteNotification();
  const [createNewListButtonText] = useMessage([
    LOCALIZATION.favouritesCreateNewListButtonText,
    LOCALIZATION.favouritesProductAddedToList,
    LOCALIZATION.favouritesCreateNewListSuccessMessage,
  ]);

  const { createFavouriteList, error, data, loading } = useCreateFavouriteList();

  const handleSubmit = (listName: string) => {
    createFavouriteList({ name: listName, item: selectedProduct || null });
  };

  const handleClick = useCallback(
    (event: React.MouseEvent) => {
      tracking(event);
      setIsModalOpen(true);
    },
    [tracking],
  );

  useEffect(() => {
    if (!loading && !error && data?.createFavouriteList) {
      const lastFavList = [...data.createFavouriteList.favourites].pop();
      getNotifications({
        type: NOTIFICATION_TYPES.SUCCESS,
        action: selectedProduct ? ACTION_TYPES.ADD_ENTRY : ACTION_TYPES.CREATE_LIST,
        listName: !lastFavList.defaultList ? lastFavList.name : null,
        listId: lastFavList.id,
      });
      setIsModalOpen(false);
      handlePostSubmit && handlePostSubmit();
    }
  }, [data, error, getNotifications, handlePostSubmit, loading, selectedProduct]);

  useEffect(() => {
    if (!loading && error) {
      getNotifications({
        type: NOTIFICATION_TYPES.ERROR,
        action: ACTION_TYPES.CREATE_LIST,
      });
    }
  }, [error, getNotifications, loading]);

  return (
    <>
      <HydraLink
        className={styles.link}
        glyphBefore={plus}
        data-purpose="create.favourite.list.modal.openButton"
        onClick={handleClick}
      >
        {createNewListButtonText}
      </HydraLink>
      {isModalOpen && (
        <ListModal
          type={LIST_MODAL_TYPES.CREATE_LIST}
          handlePostSubmit={handleSubmit}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          loading={loading}
        />
      )}
    </>
  );
};

export default track(tagComponent('CreateListLink'))(CreateListLink);
