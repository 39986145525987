import { useCallback, useState } from 'react';
import { useMutation } from '@apollo/client';
import { useUserDataContext } from 'modules/customer-account/pages/UserData/UserData.state';
import { SAVE_PREFERENCES_MUTATION } from 'modules/customer-account/pages/UserData/UserData.mutation.js';
import {
  GET_CUSTOMER_GREETING_DATA,
  GET_PREFERENCES,
} from 'modules/customer-account/pages/UserData/UserData.query';

import {
  PreferenceActions,
  ContrastValues,
} from 'modules/customer-account/pages/PreferencesData/preferences.types';

type ActionType = PreferenceActions.SET_CONTRAST | PreferenceActions.SET_GREETING;

interface PreferencesType {
  /**  greeting value */
  greeting?: string | null;
  /** contrast value */
  contrast?: ContrastValues.DARK | ContrastValues.LIGHT | null;
}

const useSavePreferences = () => {
  const [preferencesData, setPreferencesData] = useState<PreferencesType>({
    greeting: null,
    contrast: null,
  });
  const { setCustomerData }: any = useUserDataContext();

  const [savePreferencesMutation, { data, error, loading }] = useMutation(
    SAVE_PREFERENCES_MUTATION,
    {
      onCompleted: (data) => {
        if (data?.savePreferences?.valid && setCustomerData) {
          const { greeting } = preferencesData;
          greeting && setCustomerData({ profilename: greeting });
        }
      },
      refetchQueries: [GET_CUSTOMER_GREETING_DATA, GET_PREFERENCES],
    },
  );

  const savePreferences = useCallback(
    (action: ActionType, preferencesData: PreferencesType) => {
      setPreferencesData(preferencesData);
      const { greeting, contrast } = preferencesData;
      const data = {
        ...(action === PreferenceActions.SET_GREETING && { greeting: greeting ?? '' }),
        ...(action === PreferenceActions.SET_CONTRAST && { contrast }),
      };

      savePreferencesMutation({
        fetchPolicy: 'no-cache',
        variables: {
          data,
        },
      });
    },
    [savePreferencesMutation],
  );

  return {
    savePreferences,
    data,
    error,
    loading,
  };
};

export default useSavePreferences;
