import { useEffect, useState } from 'react';

const useConsentSSR = () => false;

const useConsent = (vendor: string) => {
  const hasConsent = () => !!window.jentis?.consent?.engine?.getVendorConsent(vendor);
  const [consent, setConsent] = useState<boolean>(hasConsent);

  const handleVendorChange = (e: any) => {
    const enabled = hasConsent();
    if (enabled !== consent) {
      setConsent(enabled);
    }
  };

  useEffect(() => {
    document.addEventListener('jentis.consent.engine.init', handleVendorChange, { once: true });
    document.addEventListener('jentis.consent.engine.vendor-change', handleVendorChange);
    return () => {
      document.removeEventListener('jentis.consent.engine.vendor-change', handleVendorChange);
      document.removeEventListener('jentis.consent.engine.init', handleVendorChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return consent;
};

export default CONFIG.IS_SSR ? useConsentSSR : useConsent;
