import React from 'react';
import classNames from 'classnames';
import { Icon, Image } from '@xxxlgroup/hydra-ui-components';
import { isArrayEmpty } from '@xxxlgroup/hydra-utils/common';
import type { FavouritesList } from 'graphql-types/generated/types-generated';
import { parseHydraImage } from 'utils/imageUtils';
import { armchair } from '@xxxlgroup/hydra-icons';
import styles from 'modules/favourites/components/FavouriteListImage/FavouriteListImage.scss';

interface FavouriteListImageProps {
  className?: string;
  imageClassName?: string;
  favouriteList: FavouritesList;
}

const FavouriteListImage = ({
  className,
  imageClassName,
  favouriteList,
}: FavouriteListImageProps) => {
  const { entries } = favouriteList ?? {};
  const isListEmpty = entries && isArrayEmpty(entries);
  const listImage = entries?.[0]?.item?.product?.picture;

  return (
    <div className={classNames(styles.listItemImgWrapper, className)}>
      <div className={classNames(styles.listItemImgFrame, imageClassName)}>
        {isListEmpty ? (
          <Icon
            glyph={armchair}
            data-testid="favourite-default-icon"
            className={styles.defaultListIcon}
          />
        ) : (
          <Image className={styles.listItemImg} source={parseHydraImage(listImage) ?? ''} />
        )}
      </div>
    </div>
  );
};

export default FavouriteListImage;
