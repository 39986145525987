import { gql } from '@apollo/client';

import { favouriteWithDefaultList as favourite } from 'graphql-fragments/fragments';

export const ADD_FAVOURITE_ITEMS_MUTATION = gql`
  ${favourite}
  mutation addFavouriteItems($favouriteData: FavouriteItemsInput!, $favouriteListId: String!) {
    addFavouriteItems(favouriteData: $favouriteData, favouriteListId: $favouriteListId) {
      ...favouriteWithDefault
    }
  }
`;

export const REMOVE_FAVOURITE_ITEM_MUTATION = gql`
  ${favourite}
  mutation removeFavouriteItem($favouriteListId: String!, $favouriteItemId: String!) {
    removeFavouriteItem(favouriteListId: $favouriteListId, favouriteItemId: $favouriteItemId) {
      ...favouriteWithDefault
    }
  }
`;

export const CREATE_FAVOURITE_LIST_MUTATION = gql`
  ${favourite}
  mutation createFavouriteList($favouriteData: FavouriteListInput!) {
    createFavouriteList(favouriteData: $favouriteData) {
      favourites {
        ...favouriteWithDefault
      }
    }
  }
`;

export const RENAME_FAVOURITE_LIST_MUTATION = gql`
  ${favourite}
  mutation renameFavouriteList($favouriteData: FavouriteListInput!, $favouriteListId: String!) {
    renameFavouriteList(favouriteData: $favouriteData, favouriteListId: $favouriteListId) {
      ...favouriteWithDefault
    }
  }
`;

export const REMOVE_FAVOURITE_LIST_MUTATION = gql`
  ${favourite}
  mutation removeFavouriteList($favouriteListId: String!) {
    removeFavouriteList(favouriteListId: $favouriteListId) {
      favourites {
        ...favouriteWithDefault
      }
    }
  }
`;
