import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { setCookie, userIdCookie } from 'utils/cookie';
import { useQuery } from '@apollo/client';
import useFeatureFlagsEnabled from 'hooks/useFeatureFlagsEnabled';
import {
  GET_CUSTOMER_GREETING_DATA,
  GET_PREFERENCES,
} from 'modules/customer-account/pages/UserData/UserData.query';
import { useAuth } from 'modules/customer-authentication/AuthContext';
import Context from 'modules/customer-account/components/GreetingContextProvider/GreetingContext';

export const GreetingContext = Context;

export const GreetingContextProvider = ({ children }) => {
  const accountEnabled = useFeatureFlagsEnabled('poseidon.account.enabled');
  const { isAnonymous } = useAuth();
  const { data, error, loading } = useQuery(GET_CUSTOMER_GREETING_DATA, {
    fetchPolicy: 'no-cache',
    skip: !accountEnabled || isAnonymous,
  });
  const { data: preferencesData } = useQuery(GET_PREFERENCES, {
    fetchPolicy: 'no-cache',
    skip: !accountEnabled || isAnonymous,
  });

  const getGreeting = (profileName, isEmail, email, firstName, lastName) => {
    if (profileName) {
      return profileName;
    }
    if (isEmail) {
      return email.split('@')[0];
    }
    return `${firstName || ''} ${lastName || ''}`.trim();
  };

  useEffect(() => {
    if (isAnonymous) {
      setCookie(userIdCookie, null);
    }
  }, [isAnonymous]);

  const contextValue = useMemo(() => {
    const customerData = (!loading && !error && data?.getCustomer) || {};
    const userId = customerData?.id;
    setCookie(userIdCookie, userId);

    const { firstname: firstName, lastname: lastName } = customerData.detail || {};
    const email = customerData.email?.description;
    const profileName = preferencesData?.getPreferences?.data?.greeting;

    const isEmail = !firstName && !lastName && !!email;
    const greeting = getGreeting(profileName, isEmail, email, firstName, lastName);

    return {
      hasError: isAnonymous || !accountEnabled || !!error,
      loading,
      greeting,
      firstName,
      lastName,
      profileName,
      email,
      isEmail,
    };
  }, [data, error, loading, accountEnabled, isAnonymous, preferencesData]);

  return <GreetingContext.Provider value={contextValue}>{children}</GreetingContext.Provider>;
};

GreetingContextProvider.propTypes = {
  /** @ignore */
  children: PropTypes.node.isRequired,
};
