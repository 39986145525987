import { gql } from '@apollo/client';

export const dimensions = gql`
  fragment dimensions on Dimensions {
    dimensions {
      type
      value
    }
  }
`;

export const price = gql`
  fragment price on Price {
    currencyIso
    value
  }
`;

export const address = gql`
  fragment address on Address {
    addition1
    companyName
    country {
      isocode
      name
    }
    dateOfBirth
    defaultAddress
    email
    fax
    firstName
    floor
    formattedAddress
    gender
    id
    lastName
    lift
    line1
    line2
    phone
    phone2
    postalCode
    pricepassNr
    region {
      countryIso
      isocode
      isocodeShort
      name
    }
    restType
    salesTaxNumbers {
      number
      rank
    }
    shippingAddress
    streetname
    streetnumber
    title
    titleCode
    town
    uid
    visibleInAddressBook
  }
`;

export const breadcrumbs = gql`
  fragment breadcrumbs on Breadcrumb {
    itemCode
    seoUrl
    name
    type
  }
`;

export const coreFacet = gql`
  fragment coreFacet on CoreFacet {
    __typename
    filterId
    id
    title
  }
`;

export const multiSelectColorValues = gql`
  fragment multiSelectColorValues on MultiSelectColorValues {
    __typename
    count
    id
    title
    valueId
  }
`;

export const multiSelectValues = gql`
  fragment multiSelectValues on MultiSelectValues {
    __typename
    count
    id
    title
    valueId
  }
`;

export const promotionResult = gql`
  ${price}
  fragment promotionResult on PromotionResult {
    consumedEntries {
      adjustedUnitPrice
      orderEntryNumber
      reductionValue
      quantity
    }
    description
    id
    name
    productPriceReduction
    promotion {
      code
    }
    promotionDiscount {
      ...price
    }
    valueDisplayed
  }
`;

export const reducedPriceData = gql`
  ${price}
  fragment reducedPriceData on ProductPriceData {
    currentPrice {
      ...price
    }
    oldPrice {
      value
    }
    currentNetPrice {
      value
    }
    oldNetPrice {
      value
    }
    last30DaysBestPrice {
      value
    }
    shippingCost {
      value
    }
    postCost {
      value
    }
    savedPercent
    productUnitDiffersToPriceUnit
  }
`;

export const services = gql`
  fragment services on OrderEntry {
    services {
      code
      consumed
      price
      restType
      totalPrice
      type
    }
  }
`;

export const commonPriceData = gql`
  ${reducedPriceData}
  fragment commonPriceData on ProductPriceData {
    ...reducedPriceData
    deliveryCost {
      value
    }
    offerAttributes {
      offerId
    }
    postCost {
      value
    }
    pricePerUnit {
      value
    }
    shippingCost {
      value
    }
    hideUvp
    sellingUnit
    sellingAmount
    productUnitDiffersToPriceUnit
  }
`;

export const mediaData = gql`
  fragment mediaData on ProductMediaData {
    presentation {
      cdnFilename
      altText
      realFilename
      fileType
    }
    datasheets {
      url
      cdnFilename
      downloadName
      documentType
    }
  }
`;

export const energyEfficiencyData = gql`
  fragment energyEfficiencyData on EnergyEfficiencyData {
    classColor
    eeClass
    datasheet {
      url
      realFilename
    }
    energyLabel2021Shown
    label {
      altText
      cdnFilename
      filename
      cdnFilename
    }
  }
`;

export const basicProduct = gql`
  ${breadcrumbs}
  ${mediaData}
  ${energyEfficiencyData}
  ${dimensions}
  ${commonPriceData}
  fragment basicProduct on Product {
    baseProduct
    deliveryData {
      deliveryCostsCharged
      assembly
      bulkyProduct
      customDeliveryType
      defaultDeliveryTimeRetrievalEnabled
      delivery
      deliveryCostsCharged
      deliveryIndicator
      fastDeliveryShown
    }
    characteristicData {
      colorMapping {
        targetColor
        webColor
      }
      detailAttributes {
        attributes {
          code
          name
          value
        }
      }
    }
    breadcrumbs {
      ...breadcrumbs
    }
    code
    mediaData {
      ...mediaData
    }
    energyEfficiencyData {
      ...energyEfficiencyData
    }
    eyecatchers {
      code
      name
    }
    style {
      code
      name
    }
    brands {
      code
      image {
        altText
        filename
        pictureHeight
        pictureWidth
      }
      name
      url
      urlCode
    }
    attributes {
      colorMapping {
        code
        targetColor
        webColor
      }
      dimensions {
        ...dimensions
        strRepresentation
        strRepresentationKey
      }
      material
    }
    name
    picture {
      altText
      cdnFilename
    }
    price {
      specialOfferTypeData {
        numberOfAsterisks
      }
      value
    }
    priceData {
      ...commonPriceData
      currentPrice {
        endTime
        specialOfferTypeData {
          numberOfAsterisks
        }
      }
    }
    reservationData {
      maxReservableAmount
      reservable
    }
    color
    url
  }
`;

export const subsidiary = gql`
  fragment subsidiary on SubsidiaryInformation {
    displayInformation {
      displayed
      location {
        department
        floor
        formattedFloorText
      }
    }
    distance
    pointOfService {
      address {
        postalCode
        streetname
        streetnumber
        town
        formattedAddress
      }
      code
      name
      geoPoint {
        latitude
        longitude
      }
      seoData {
        url
      }
    }
    reservable
    selfService
    shippingInformation {
      deliveryTime
      deliveryTimeText
      shippingType
    }
  }
`;
export const configuredProduct = gql`
  fragment configuredProduct on Product {
    configuredProduct
    configuredProductData {
      attachments {
        altText
        url
      }
      images {
        altText
        url
      }
      configuredId
      productNumber
      description
      name
      restType
      summary
    }
  }
`;

export const paymentAddress = gql`
  fragment paymentAddress on Address {
    addressId
    companyName
    country {
      isocode
      name
    }
    countryIsoCode
    email
    firstName
    floor
    gender
    lastName
    lift
    line1
    line2
    phone
    postalCode
    pricepassNr
    regionIsoCode
    region {
      isocode
      name
    }
    salesTaxNumbers {
      number
    }
    streetname
    streetnumber
    titleCode
    town
  }
`;

export const deliveryModes = gql`
  fragment deliveryModes on OrderEntry {
    deliveryModes {
      assemblyCost
      code
      customShippingType
      deliveryCost {
        value
      }
      deliveryCostSpecial {
        value
      }
      deliveryCostSpecialType
      deliveryTime
      description
      deliveryTimeText
      isFreeDeliveryCost
      name
      selfServiceStores {
        address {
          formattedAddress
          line1
          line2
          phone
          postalCode
          region {
            countryIso
            isocode
            name
          }
          streetname
          streetnumber
          town
        }
        code
        openingHoursText
        name
        geoPoint {
          latitude
          longitude
        }
        seoData {
          canonicalUrl
          title
          description
          noIndex
          noFollow
          url
        }
      }
    }
    deliveryMode {
      code
      description
      name
    }
  }
`;

export const messages = gql`
  fragment messages on MessageData {
    arguments {
      type
      value
    }
    message
  }
`;

export const paymentMode = gql`
  fragment paymentMode on PaymentMode {
    active
    code
    paymentModeId
    description
    logo {
      altText
      cdnFilename
      fileExtension
      format
      mediaProvider
      mime
      pictureHeight
      pictureWidth
      realFilename
      seoText
      url
    }
    mobileDescription
    name
    paymentCost {
      currencyIso
      value
    }
    pgProvider
    pspGroupedCode
    redirectHint
    redirectMessage
    redirectPageHeader
  }
`;

export const deliveryAddress = gql`
  fragment deliveryAddress on Address {
    addition1
    addressId
    email
    firstName
    floor
    gender
    lastName
    lift
    line1
    line2
    phone
    postalCode
    pricepassNr
    regionIsoCode
    region {
      isocode
      name
    }
    streetname
    streetnumber
    titleCode
    town
  }
`;

export const pointOfService = gql`
  fragment pointOfService on PointOfService {
    name
    code
    address {
      formattedAddress
    }
    description
  }
`;

export const favouriteProduct = gql`
  ${dimensions}
  ${commonPriceData}
  fragment favouriteProduct on Product {
    type
    availabilityStatus
    baseProduct
    additionalProductExpenses {
      price
      type
      includedInProductPrice
    }
    attributes {
      dimensions {
        ...dimensions
        strRepresentation
        strRepresentationKey
      }
      material
    }
    mediaData {
      presentation {
        cdnFilename
        altText
        realFilename
        fileType
      }
      datasheets {
        url
        cdnFilename
        downloadName
        documentType
      }
    }
    code
    energyEfficiencyData {
      classColor
      eeClass
      datasheet {
        url
        realFilename
      }
      energyLabel2021Shown
      label {
        altText
        cdnFilename
        filename
        cdnFilename
      }
    }
    color
    name
    picture {
      altText
      cdnFilename
    }
    orderData {
      buyable
    }
    price {
      value
      specialOfferTypeData {
        numberOfAsterisks
      }
    }
    priceData {
      ...commonPriceData
      currentPrice {
        specialOfferTypeData {
          numberOfAsterisks
        }
      }
    }
    statisticKPI
    url
  }
`;

export const favourite = gql`
  ${favouriteProduct}
  fragment favourite on FavouritesList {
    id
    name
    entries {
      id
      item {
        code
        entryNumber
        product {
          ...favouriteProduct
        }
        quantity
        type
      }
    }
  }
`;

export const favouriteWithDefaultList = gql`
  ${favouriteProduct}
  fragment favouriteWithDefault on FavouritesList {
    id
    name
    defaultList
    entries {
      id
      item {
        code
        entryNumber
        product {
          ...favouriteProduct
        }
        quantity
        type
      }
    }
  }
`;

export const cart = gql`
  ${promotionResult}
  ${price}
  ${deliveryAddress}
  ${deliveryModes}
  ${paymentMode}
  ${pointOfService}
  ${basicProduct}
  ${configuredProduct}
  ${services}
  ${messages}
  ${paymentAddress}
`;

export const order = gql`
  ${promotionResult}
  ${price}
  ${deliveryAddress}
  ${paymentMode}
  ${pointOfService}
  ${deliveryModes}
  ${price}
  ${services}
  ${messages}
  ${paymentAddress}
  fragment order on Order {
    code
    appliedOrderPromotions {
      ...promotionResult
      removable
      usedCouponCodes
    }
    appliedProductPromotions {
      ...promotionResult
      removable
      usedCouponCodes
    }
    appliedVoucherDataList {
      appliedValue {
        ...price
      }
      applied
      code
      currency {
        name
      }
      description
      freeShipping
      overflowAmount
      promotionCode
      name
      value
      violationMessage
      restrictions {
        type
        ... on ProductQuantityRestriction {
          unit
          quantity
        }
        ... on ProductGroupRestriction {
          categoryMappingCodesList
          included
        }
        ... on ProductRestriction {
          products {
            code
          }
        }
        ... on ProductBrandRestriction {
          brands {
            code
          }
        }
        ... on ProductConfiguredRestriction {
          configuredOnly
        }
        ... on StatisticIdentifierRestriction {
          statisticIdentifier
        }
        ... on ProductEyecatcherRestriction {
          eyecatchers {
            code
          }
        }
        ... on ProductCategoryRestriction {
          categories {
            code
          }
        }
      }
      voucherType
    }
    code
    currency {
      isocode
    }
    deliveryAddress {
      ...deliveryAddress
    }
    paymentMode {
      ...paymentMode
    }
    paymentModes {
      active
      code
      description
      logo {
        altText
        cdnFilename
        fileExtension
        format
        mediaProvider
        mime
        pictureHeight
        pictureWidth
        realFilename
        seoText
        url
      }
    }
    entries {
      pointOfService {
        ...pointOfService
      }
      ...deliveryModes
      deliveryTime
      entryNumber
      product {
        ...basicProduct
      }
      quantity
      totalPrice {
        ...price
      }
      selectedDeliveryMode
      selectedQuantity
      ...services
      unitBasedCalculation {
        deliveryAmount
        oldPriceTotal {
          currencyIso
          formattedValue
          priceType
          value
        }
        packageAmount
        selectedDeliveryAmount
        amount
      }
      updateable
    }
    guid
    messages {
      ...messages
    }
    paymentAddress {
      ...paymentAddress
    }
    deliveryCost {
      value
    }
    deliveryCostAssembling {
      value
    }
    deliveryCostWithoutSpecial {
      value
    }
    orderType
    saveAddresses
    subOrders {
      code
      selectedStore {
        code
        name
        seoData {
          url
        }
      }
      totalPriceWithTax {
        currencyIso
        value
      }
    }
    subTotal {
      ...price
    }
    totalItems
    totalPrice {
      ...price
    }
    totalPriceWithTax {
      ...price
    }
    totalTax {
      ...price
    }
    totalPriceWithoutCoupons {
      ...price
    }
    totalNetPriceWithoutCoupons {
      ...price
    }
    voucherSum {
      ...price
    }
  }
`;

export const productConfiguratorResponse = gql`
  fragment productConfiguratorResponse on ProductConfigurationResponse {
    openType
    restType
    url
  }
`;

export const customerDetails = gql`
  fragment customerDetails on CustomerDetails {
    salutation
    title
    firstname
    lastname
    birthdate
    phone
    created
  }
`;

export const addressData = gql`
  fragment addressData on AddressData {
    id
    maddress
    saddress
    baddress
    gender
    titleCode
    firstName
    lastName
    email
    phone
    companyName
    attn
    salesTaxNumbers {
      id
      number
      rank
      created
    }
    streetname
    streetnumber
    floor
    lift
    postalCode
    town
    country
    addition1
    created
  }
`;

export const productCampaignAndListingData = gql`
  ${dimensions}
  ${commonPriceData}
  ${energyEfficiencyData}
  fragment productCampaignAndListingData on Product {
    availabilityStatus
    averageRating
    name
    numberOfReviews
    url
    code
    firstItemOfPage
    hasRatings
    legalInformationShown
    color
    eyecatcherABIsShown
    freeDelivery
    attributes {
      colorMapping {
        code
        targetColor
        webColor
      }
      dimensions {
        ...dimensions
        strRepresentation
        strRepresentationKey
      }
      material
    }
    priceData {
      ...commonPriceData
      currentPrice {
        specialOfferTypeData {
          numberOfAsterisks
        }
      }
    }
    configurable
    energyEfficiencyData {
      ...energyEfficiencyData
    }
    mediaData {
      presentation {
        altText
        cdnFilename
        realFilename
        seoText
        fileType
      }
      datasheets {
        url
        realFilename
        documentType
      }
    }
    brands {
      name
    }
    eyecatchers {
      code
      style
    }
    orderData {
      buyable
    }
    style {
      code
      name
    }
    statisticKPI
    variantContainerData {
      variantHighlightType
      variantGroups {
        name
        variantValues {
          enabled
          product {
            availabilityStatus
            code
            color
            name
            url
            priceData {
              ...commonPriceData
              currentPrice {
                specialOfferTypeData {
                  numberOfAsterisks
                }
              }
            }
          }
          productPicture {
            altText
            cdnFilename
            hashCode
            fileType
            filename
            fileExtension
          }
          restType
          selected
          value
        }
      }
    }
    hotdealData {
      assignedStore {
        name
      }
    }
  }
`;

export const seoData = gql`
  fragment seoData on Seo {
    baseStoreRelatedItems {
      locale
      url
    }
    canonicalUrl
    title
    url
    description
    keywords
    name
    noFollow
    noIndex
  }
`;

export const brands = gql`
  fragment brands on Brand {
    code
    image {
      altText
      filename
      fileExtension
      pictureHeight
      pictureWidth
    }
    showBrandnameInPdpTitle
    name
    url
    urlCode
  }
`;

export const currentPrice = gql`
  fragment currentPrice on Price {
    endTime
    value
    currencyIso
    specialOfferTypeData {
      numberOfAsterisks
    }
  }
`;

export const hotDealData = gql`
  fragment hotDealData on HotDealData {
    assignedStore {
      name
      regularOpeningHours
      code
      address {
        email
        firstName
        lastName
        phone
        postalCode
        streetname
        streetnumber
        town
      }
    }
    itemNumber
  }
`;

export const ratingStatistic = gql`
  fragment ratingStatistic on RatingStatistic {
    ratingCount
    ratingValue
    ratingStatisticPerRatingValue {
      ratingValue
      ratingValueCount
    }
  }
`;

export const pagination = gql`
  fragment pagination on ReviewList {
    pagination {
      currentPage
      hasNextPage
      hasPreviousPage
      pageSize
      totalPages
      totalResults
    }
  }
`;

export const dimensionalRatingStatisticData = gql`
  fragment dimensionalRatingStatisticData on ReviewList {
    dimensionalRatingStatisticData {
      ratingValue
      ratingValueType
      ratingStatisticPerRatingValue {
        ratingValue
        ratingValueCount
      }
    }
  }
`;

export const dimensionTypes = gql`
  fragment dimensionTypes on ReviewList {
    dimensionTypes {
      type
      value
    }
  }
`;

export const reviews = gql`
  fragment reviews on ReviewList {
    reviews {
      authorAlias
      authorType
      body
      created
      countryCrossedData {
        language
        tld
      }
      verifiedAuthor
      variantData {
        name
        title
        value
      }
      dimensionalRatingValues {
        type
        value
      }
      id
      ratingValue
      title
      replies {
        alias
        createdTS
        message
      }
    }
  }
`;
