import {
  FavouritesStateType,
  Action,
  ActionType,
} from 'modules/favourites/pages/Favourites/Favourites.types';
import { mapFavProducts, getArrayCodesFromEntries } from 'modules/favourites/utils/favourites';
import { FavouritesSource } from 'utils/mobileApp.variables';
import { FavouritesList } from 'graphql-types/generated/types-generated';

const updateLists = (state: FavouritesStateType, dataList: FavouritesList) => {
  const { lists } = state.favourites;
  if (lists.length < 2) {
    return [dataList];
  }
  const newLists: FavouritesList[] = [];
  lists.forEach((list, index) => {
    list.id === dataList.id ? (newLists[index] = dataList) : (newLists[index] = list);
  });
  return newLists;
};

const sendFavouritesToFlutter = (state: FavouritesStateType) => {
  if (state.isMobileApp) {
    const products = getArrayCodesFromEntries();
    const message = {
      data: { products },
    };
    (window as any)?.flutter_inappwebview?.callHandler(
      FavouritesSource.FAVOURITES_FROM_WEB_VIEW,
      JSON.stringify(message),
    );
  }
};

export const favouritesReducer = (state: FavouritesStateType, action: Action) => {
  switch (action.type) {
    case ActionType.UPDATE_FAVOURITES: {
      const newState = {
        ...state,
        favourites: { ...action.payload, products: mapFavProducts(action.payload.products) },
      };
      sendFavouritesToFlutter(newState);
      return newState;
    }
    case ActionType.UPDATE_LIST: {
      const newState = {
        ...state,
        favourites: {
          ...state.favourites,
          products: [],
          lists: updateLists(state, action.payload),
        },
      };
      sendFavouritesToFlutter(newState);
      return newState;
    }
    case ActionType.UPDATE_FROM_ACCOUNT:
      return { ...state, isComingFromAccount: action.payload };
    case ActionType.UPDATE_IS_MOBILE_APP: {
      const newState = { ...state, isMobileApp: action.payload };
      sendFavouritesToFlutter(newState);
      return newState;
    }
    default:
      return state;
  }
};
