import { useCallback } from 'react';
import useGetFavouriteProductsQuery from 'modules/favourites/hooks/useGetFavouriteProductsQuery';

import { getCodesFromEntries } from 'modules/favourites/utils/favourites';

const useGetFavouritesFromClient = () => {
  const { getFavouriteProducts, data, loading, error } = useGetFavouriteProductsQuery();
  const localCodes = getCodesFromEntries();

  const getFavouriteFromClient = useCallback(() => {
    getFavouriteProducts(localCodes);
  }, [getFavouriteProducts, localCodes]);

  return {
    getFavouriteFromClient,
    dataGetFromClient: localCodes ? data : null,
    loadingGetFromClient: loading,
    errorGetFromClient: error,
  };
};

export default useGetFavouritesFromClient;
