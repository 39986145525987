import { useCallback, useState } from 'react';
import useGetFavouriteProductsQuery from 'modules/favourites/hooks/useGetFavouriteProductsQuery';
import { ProductType } from 'modules/favourites/pages/Favourites/Favourites.types';

import { getCodesFromEntries, removeProductFromLocal } from 'modules/favourites/utils/favourites';

export interface ProductRemoveDataType {
  getProducts: {
    products: ProductType[];
  };
}

const useRemoveFavouriteFromClient = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [dataProducts, setDataProducts] = useState<ProductRemoveDataType | null>(null);
  const { getFavouriteProducts, data, loading, error } = useGetFavouriteProductsQuery();

  const removeFavouriteFromClient = useCallback(
    async (code: string) => {
      setIsLoading(true);
      setDataProducts(null);
      removeProductFromLocal(code);
      const codes = getCodesFromEntries();
      if (codes) {
        await getFavouriteProducts(codes);
      } else {
        setDataProducts({ getProducts: { products: [] } });
      }
      setIsLoading(false);
    },
    [getFavouriteProducts],
  );

  return {
    removeFavouriteFromClient,
    dataRemoveFromClient: dataProducts || data,
    loadingRemoveFromClient: isLoading || loading,
    errorRemoveFromClient: error,
  };
};

export default useRemoveFavouriteFromClient;
