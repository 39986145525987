import { useCallback } from 'react';
import { useMutation } from '@apollo/client';

import { ADD_FAVOURITE_ITEMS_MUTATION } from 'modules/favourites/queries/Favourites.mutation';
import { ProductItemType } from 'modules/favourites/pages/Favourites/Favourites.types';

const useAddFavouriteToServer = () => {
  const [addFavouriteMutation, { data, error, loading }] = useMutation(
    ADD_FAVOURITE_ITEMS_MUTATION,
  );

  const addFavouriteToServer = useCallback(
    async (products: ProductItemType[], favouriteListId = 'default') => {
      await addFavouriteMutation({
        fetchPolicy: 'no-cache',
        variables: {
          favouriteData: { items: products },
          favouriteListId,
        },
      });
    },
    [addFavouriteMutation],
  );

  return {
    addFavouriteToServer,
    dataAddToServer: data,
    errorAddToServer: error,
    loadingAddToServer: loading,
  };
};

export default useAddFavouriteToServer;
