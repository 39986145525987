import { gql } from '@apollo/client';
import { customerDetails, preferences } from 'graphql-fragments/index';

export const GET_CUSTOMER_DATA = gql`
  query getCustomerData {
    getCustomerData {
        ${customerDetails}
    }
  }
`;

export const GET_CUSTOMER_EXTENDED_DATA = gql`
  query getCustomer {
    getCustomer {
      detail {
        ${customerDetails}
      }
      email {
        description
      }
    }
  }
`;

export const GET_CUSTOMER_GREETING_DATA = gql`
  query getCustomer {
    getCustomer {
      id
      detail {
        firstname
        lastname
      }
      email {
        description
      }
    }
  }
`;

export const GET_PREFERENCES = gql`
 query getPreferences {
    getPreferences {
        ${preferences}
    }
  }
`;
