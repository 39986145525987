import getVisitorInformation, { type VisitorInformation } from 'services/VisitorInformationService';
import { getCookie } from 'utils/cookie';
import { COOKIE_NAME } from 'utils/i18n';

export type VisitorData = {
  user?: string;
  visitor: VisitorInformation;
  currentcart: any;
  shop: {
    country: string;
    language: string;
    shop_id: string; // eslint-disable-line camelcase
    rail: string;
  };
};

interface Language {
  languageCode: string;
  locale: string;
  rail: string;
}

export default function initialiseVisitorData({ languageCode, locale, rail }: Language) {
  const visitor = getVisitorInformation();

  const tracking: VisitorData = {
    user: visitor.sessionId,
    visitor,
    currentcart: {},
    shop: {
      country: languageCode,
      language: (getCookie(COOKIE_NAME) || locale)?.split(/[_-]/)[0],
      rail,
      // ShopId needed for vendor - causes an error otherwise
      shop_id: '00000test',
    },
  };

  /**
   * Expose information to window - for other external tools & enable an easy switch of source of truth for the session & userId
   */
  window.tracking = tracking;
  return tracking;
}
