import { v4 as uuidv4 } from 'uuid';
import { isArrayEmpty } from '@xxxlgroup/hydra-utils/common';
import { local, session } from '@xxxlgroup/hydra-utils/storage';
import {
  FavouritesType,
  ProductLocalType,
  FavouritesLocalType,
  ProductItemType,
  ProductType,
} from 'modules/favourites/pages/Favourites/Favourites.types';
import { Favourite, FavouritesList } from 'graphql-types/generated/types-generated';

export const FAVOURITES_LOCAL = 'newFavourites';
export const isNumber = /^\d+$/;

// get codes from fav object as a string
export const getCodesFromEntries = () => {
  const products: ProductLocalType[] = local.getItem(FAVOURITES_LOCAL)?.products;
  return products?.map((product) => product.item.code).join(',') || '';
};

// get codes from fav object as an array
export const getArrayCodesFromEntries = () => {
  const products: ProductLocalType[] = local.getItem(FAVOURITES_LOCAL)?.products;
  return products?.map((product) => product.item.code) || [];
};

// from local
const hasEntry = (favourites: FavouritesLocalType, code: string) =>
  favourites?.products?.some((product) => product.item.code === code);

export const addProductToLocal = (product: ProductItemType) => {
  const favouritesData = local.getItem(FAVOURITES_LOCAL);

  const newClientEntry = {
    id: uuidv4(),
    item: {
      code: product.code,
      type: product.type,
    },
  };

  const clientFavourites = {
    products: hasEntry(favouritesData, product.code)
      ? favouritesData.products
      : [...(favouritesData?.products || []), newClientEntry],
  };

  local.setItem(FAVOURITES_LOCAL, clientFavourites);
};

export const removeProductFromLocal = (code: string) => {
  const favouritesData: { products: ProductLocalType[] } = local.getItem(FAVOURITES_LOCAL);

  const clientFavourites = {
    products: favouritesData.products.filter((product) => product.item.code !== code),
  };

  local.setItem(FAVOURITES_LOCAL, clientFavourites);
};

export const findProductInLists = (lists: FavouritesList[], code: string) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const list of lists) {
    const found = list?.entries?.find((entry) => entry?.item?.code === code);
    if (found) return { id: found.id, listId: list.id, name: list.name };
  }
  return null;
};

export const findProductInFavourites = (favourites: FavouritesType, code: string) => {
  const { products = [], lists = [] } = favourites;
  const result = products.find((product) => product?.item?.code === code);
  return result || findProductInLists(lists, code);
};

export const getProductsToAdd = () => {
  const productsToAdd = local.getItem(FAVOURITES_LOCAL)?.products;
  const result: ProductItemType[] = [];
  if (!isArrayEmpty(productsToAdd)) {
    productsToAdd.forEach((product: ProductLocalType) => {
      result.push({ code: product.item.code, type: 'Product' });
    });
  }
  return result;
};

export const removeFavouritesFromLocal = () => {
  local.removeItem(FAVOURITES_LOCAL);
};

export const getFavouritesProducts = (favourites: FavouritesType) => {
  const { products = [], lists = [] } = favourites;
  if (products.length) {
    return products;
  }

  const entries: any[] = [];
  lists.forEach((list) => {
    entries.push(...(list.entries ?? []));
  });

  return entries;
};

export const mapFavProducts = (products: ProductType[]) => {
  const localProducts: ProductLocalType[] = local.getItem(FAVOURITES_LOCAL)?.products;
  if (isArrayEmpty(products) || isArrayEmpty(localProducts)) {
    return [];
  }

  return localProducts
    .map((localProduct) => {
      const productData = products.find(({ code }) => localProduct?.item?.code === code);
      const id = localProduct?.id || uuidv4();
      return (
        productData && {
          id,
          item: {
            entryNumber: id,
            code: productData.code,
            product: productData,
            type: 'PRODUCT',
          },
        }
      );
    })
    .filter((product) => product);
};

export const getProductCode = (products: Favourite[], id: string) => {
  const found = products.find((entry) => entry.id === id);
  return found?.item?.product?.code;
};

/**
 * Retrieves or generates a session ID and saves it in the session storage.
 * @returns {string} The session ID.
 */
export const getSessionId = () => {
  let sessionId = session.getItem('sessionId');
  if (!sessionId) {
    sessionId = uuidv4();
    session.setItem('sessionId', sessionId);
  }
  return sessionId;
};

/**
 * Sets the update indicator for favorites using the current session ID.
 */
export const setUpdateFavourites = () => {
  const sessionId = getSessionId();
  local.setItem('favouritesUpdated', sessionId);
};

// Add products(array of codes) from flutter App to local storage.
export const updateClientFavouriteFromCodes = (codes: string[]) => {
  removeFavouritesFromLocal();
  codes.forEach((code) => {
    const product = { code, type: 'Product' };
    addProductToLocal(product);
  });
};
