import { useLocation } from 'react-router-dom';

export const DISABLE_3RD_PARTY_SCRIPT_KEY = 'disable3rdPartyScripts';

export const use3rdPartyScriptsAllowed = () => {
  const { search } = useLocation();
  return are3rdPartyScriptsAllowed(search);
};

// this function should only be used outside React
export const are3rdPartyScriptsAllowed = (searchParams: string) => {
  if (typeof window !== 'undefined' && window.Cypress) {
    return false;
  }

  const parameter = new URLSearchParams(searchParams).get(DISABLE_3RD_PARTY_SCRIPT_KEY);
  const session =
    typeof window !== 'undefined' && window.sessionStorage.getItem(DISABLE_3RD_PARTY_SCRIPT_KEY);

  let isAllowed = true;

  if (parameter === '1' && session !== '1') {
    typeof window !== 'undefined' &&
      window.sessionStorage.setItem(DISABLE_3RD_PARTY_SCRIPT_KEY, '1');
    isAllowed = false;
  } else if (parameter === '0' && session === '1') {
    typeof window !== 'undefined' && window.sessionStorage.removeItem(DISABLE_3RD_PARTY_SCRIPT_KEY);
  } else if (session === '1') {
    isAllowed = false;
  }

  return isAllowed;
};
