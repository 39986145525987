import { useLocation } from 'react-router-dom';

const useIsModeHeadless = () => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const isModeHeadless = !!params.get('modeHeadless');

  return !!isModeHeadless;
};

export default useIsModeHeadless;
