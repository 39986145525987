import React, { useCallback } from 'react';
import useRoutes from 'hooks/useRoutes';
import { Content, useNotifications } from '@xxxlgroup/hydra-ui-components';
import useMessage from 'components/Message/useMessage';
import Message from 'components/Message';
import { LOCALIZATION, NOTIFICATION_TYPES, ACTION_TYPES } from 'modules/favourites/constants';

interface NotificationProps {
  action: ACTION_TYPES;
  listId?: string | null;
  listName?: string | null;
  type: NOTIFICATION_TYPES;
}

const useFavouriteNotification = () => {
  const {
    favouritesPage: { root },
  } = useRoutes();
  const notifications = useNotifications();

  const [defaultListName, getDataError] = useMessage([
    LOCALIZATION.favouritesDefaultListName,
    LOCALIZATION.favouritesGetDataError,
  ]);

  const getVariables = useCallback(
    (listName?: string | null, listId?: string | null) => {
      const listUrl = listId ? `${root}?id=${listId}` : root;
      return {
        listName: listName || defaultListName,
        listUrl,
        cartUrl: '/cart',
      };
    },
    [defaultListName, root],
  );

  const getTextMessage = useCallback(
    (code: string, variables: any) => (
      <Message code={code} values={variables}>
        {(message) => <Content content={message} size="sm" tag="span" />}
      </Message>
    ),
    [],
  );

  const getErrorMessage = useCallback(
    (action: ACTION_TYPES, variables: any) => {
      switch (action) {
        case ACTION_TYPES.ADD_ENTRY:
          return getTextMessage(LOCALIZATION.favouritesAddEntryError, variables);
        case ACTION_TYPES.REMOVE_ENTRY:
          return getTextMessage(LOCALIZATION.favouritesRemoveEntryError, variables);
        case ACTION_TYPES.CREATE_LIST:
          return getTextMessage(LOCALIZATION.favouritesCreateNewListErrorMessage, variables);
        case ACTION_TYPES.UPDATE_LIST:
          return getTextMessage(LOCALIZATION.favouritesUpdateListErrorMessage, variables);
        case ACTION_TYPES.DELETE_LIST:
          return getTextMessage(LOCALIZATION.favouritesDeleteListErrorMessage, variables);
        case ACTION_TYPES.MOVE_ENTRY_TO_CART:
          return getTextMessage(LOCALIZATION.favouritesEntryMoveToCartError, variables);
        case ACTION_TYPES.EMPTY_LIST:
          return getTextMessage(LOCALIZATION.favouritesDeleteListErrorMessage, variables);
        default:
          return getDataError;
      }
    },
    [getDataError, getTextMessage],
  );

  const getSuccessMessage = useCallback(
    (action: ACTION_TYPES, variables: any) => {
      switch (action) {
        case ACTION_TYPES.ADD_ENTRY:
          return getTextMessage(LOCALIZATION.favouritesAddEntrySuccess, variables);
        case ACTION_TYPES.REMOVE_ENTRY:
          return getTextMessage(LOCALIZATION.favouritesRemoveEntrySuccess, variables);
        case ACTION_TYPES.CREATE_LIST:
          return getTextMessage(LOCALIZATION.favouritesCreateNewListSuccessMessage, variables);
        case ACTION_TYPES.UPDATE_LIST:
          return getTextMessage(LOCALIZATION.favouritesUpdateListSuccessMessage, variables);
        case ACTION_TYPES.DELETE_LIST:
          return getTextMessage(LOCALIZATION.favouritesDeleteListSuccessMessage, variables);
        case ACTION_TYPES.MOVE_ENTRY_TO_CART:
          return getTextMessage(LOCALIZATION.favouritesEntryMoveToCartSuccess, variables);
        case ACTION_TYPES.EMPTY_LIST:
          return getTextMessage(LOCALIZATION.favouritesEmptyListSuccessMessage, variables);
        default:
          return null;
      }
    },
    [getTextMessage],
  );

  const getMessage = useCallback(
    (type: NOTIFICATION_TYPES, action: ACTION_TYPES, variables: any) => {
      if (type === NOTIFICATION_TYPES.ERROR) {
        return getErrorMessage(action, variables);
      }

      return getSuccessMessage(action, variables);
    },
    [getErrorMessage, getSuccessMessage],
  );

  const getNotifications = useCallback(
    ({ listName, listId, type, action }: NotificationProps) => {
      const variables = getVariables(listName, listId);
      const zIndex = 2030;
      notifications(type, getMessage(type, action, variables), { layer: zIndex });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getMessage, getVariables],
  );

  return {
    getNotifications,
  };
};

export default useFavouriteNotification;
