export const seoData = `
baseStoreRelatedItems {
  locale
  url
}
canonicalUrl
title
url
description
keywords
name
noFollow
noIndex`;

export const mediaData = `
presentation {
  cdnFilename
  altText
  realFilename
  fileType
}
datasheets {
  url
  cdnFilename
  downloadName
  documentType
}`;

export const brands = `
code
image {
  altText
  filename
  fileExtension
  pictureHeight
  pictureWidth
}
showBrandnameInPdpTitle
name
url
urlCode`;

export const energyEfficiencyData = `classColor
eeClass
datasheet {
  url
  realFilename
}
energyLabel2021Shown
label {
  altText
  cdnFilename
  filename
  cdnFilename
}`;

export const currentPrice = `
  endTime
  value
  currencyIso
  specialOfferTypeData {
    numberOfAsterisks
  }
`;

export const hotdealsData = `
 assignedStore {
   name
   regularOpeningHours
   code
   address {
     email
     firstName
     lastName
     phone
     postalCode
     streetname
     streetnumber
     town
   }
 }
 itemNumber`;

export const dimensions = `
dimensions {
  type
  value
}
`;
