import React, { useCallback } from 'react';
import { ProductItemType } from 'modules/favourites/pages/Favourites/Favourites.types';
import useMessage from 'components/Message/useMessage';
import { LOCALIZATION } from 'modules/favourites/constants';
import FavouriteListImage from 'modules/favourites/components/FavouriteListImage';
import track from 'react-tracking';
import { useTracking } from 'utils/tracking/hooks';
import { tagComponent } from 'utils/tracking/tracking';
import type { FavouritesList } from 'graphql-types/generated/types-generated';

import styles from 'modules/favourites/components/ListSelectItem/ListSelectItem.scss';

interface ListSelectItemProps {
  handleItemClick: (products: ProductItemType[], favouriteListId: string) => void;
  favouriteList: FavouritesList;
  selectedProduct: ProductItemType;
}

const ListSelectItem = (props: ListSelectItemProps) => {
  const { handleItemClick, favouriteList, selectedProduct } = props;
  const tracking = useTracking(props, 'ListSelectItem');
  const favouritesDefaultListName = useMessage(LOCALIZATION.favouritesDefaultListName);
  const listName = favouriteList.defaultList ? favouritesDefaultListName : favouriteList.name || '';

  const handleClick = useCallback(
    (event: React.MouseEvent) => {
      tracking(event);
      const listId = favouriteList?.defaultList ? 'default' : favouriteList.id;
      handleItemClick([selectedProduct], listId ?? '');
    },
    [favouriteList?.defaultList, favouriteList.id, handleItemClick, selectedProduct, tracking],
  );

  return (
    <div className={styles.listSelectItemWrapper}>
      <button
        type="button"
        className={styles.listItemButton}
        data-testid="favourites.listSelectItem"
        onClick={handleClick}
      >
        <FavouriteListImage
          favouriteList={favouriteList}
          className={styles.listImage}
          imageClassName={styles.listItemImgFrame}
        />
        <div className={styles.listName}>{listName}</div>
      </button>
    </div>
  );
};

export default track(tagComponent('ListSelectItem'))(ListSelectItem);
