import { ApolloError } from '@apollo/client/errors';
import type {
  Favourite,
  FavouritesList,
  Image,
  Product,
} from 'graphql-types/generated/types-generated';
import { Dispatch } from 'react';

// An enum with all the types of actions to use in our reducer
export enum ActionType {
  UPDATE_FAVOURITES = 'UPDATE_FAVOURITES',
  UPDATE_LIST = 'UPDATE_LIST',
  UPDATE_FROM_ACCOUNT = 'UPDATE_FROM_ACCOUNT',
  UPDATE_IS_MOBILE_APP = 'UPDATE_IS_MOBILE_APP',
}

// An interface for our actions
export interface Action {
  type: ActionType;
  payload: any;
}

export interface ProductType extends Product {
  picture: Image;
}

export interface FavouritesType {
  products: Favourite[];
  lists: FavouritesList[];
  loading: boolean;
  errors: ApolloError | undefined;
}

export interface FavouritesStateType {
  favourites: FavouritesType;
  isComingFromAccount: boolean;
  isMobileApp: boolean;
}

export interface FavouritesContextInterface {
  favouritesState: FavouritesStateType;
  favouritesDispatch: Dispatch<Action>;
}

export interface ProductItemType {
  code: string;
  type: string;
}

export interface ProductLocalType {
  id: string;
  item: ProductItemType;
}

export interface FavouritesLocalType {
  products: ProductLocalType[];
}
