import { Cart } from 'types/types';

type actionType = 'view' | 'click';

export interface UseRetailMediaScriptProps {
  /* action is by default 'view' */
  action?: actionType;
  /* cart data needed to build the window t2S object */
  cartData?: Cart | null;
  /* CategoryId is the category id of the current list page */
  categoryId?: string;
  /* delay even and trigger it somewhere else  */
  skip?: boolean;
  /* pageName is the predefined pageName of the current page */
  pageName?: RetailMediaPageNamesType;
  /* pageNr is the page number of the current list page as string */
  pageNr?: number;
  /* productData is the productCode of the current product, or an array of productcodes */
  productData?: string | string[];
  /* searchTerm is the search term used in the search results page */
  searchTerm?: string;
}

export interface RetailMediaListingProps {
  pageName: RetailMediaPageNamesType;
  productData: string[];
  pageNr: number;
  categoryId: string;
  searchTerm: string;
  skip: boolean;
}

export interface RetailMediaCartProps {
  pageName: RetailMediaPageNamesType;
  cartData: Cart;
}

export interface DefaultT2SParams {
  cID: string;
  pID: string;
  hasRankOption: boolean;
  uEM?: string;
}

export interface CartT2SParams extends DefaultT2SParams {
  en: string;
  iID: string;
  qTE: string;
  bS: string;
  priceL: string;
  offerID: string;
}

export interface OrderT2SParams extends CartT2SParams {
  oID: string;
}

export interface DefaultListingT2SParams extends DefaultT2SParams {
  // productcodes
  iID: string;
  // pagenr
  pageNbr: string;
}

export interface CategoryViewT2SParams extends DefaultListingT2SParams {
  /* iID is the productID and provides the product context */
  iID: string; // maybe add regex
  /* aID is the categoryID */
  aID: string;
}

export interface SearchViewT2SParams extends DefaultListingT2SParams {
  /* pageId is the pageId of the current page which can overwrite the pagemapping logic */
  iID: string;
  /* searchTerm is the search term used in the search results page */
  kW: string;
}

export enum RetailMediaPageNames {
  ACCOUNT_CREATION_END = 'account_creation_end',
  ACCOUNT_CREATION_START = 'account_creation_start',
  ADD_TO_CART = 'add_to_cart',
  CART = 'cart',
  CONTENT = 'content',
  HOME = 'home',
  LIST = 'list',
  LIST_PAGINATION = 'list_pagination',
  MPLP = 'mplp',
  LOGIN = 'login',
  NOT_FOUND = 'not_found',
  POST_PAYMENT = 'postPayment',
  PRODUCT = 'product',
  PURCHASE_BEGIN = 'purchase_begin',
  SEARCH_RESULTS = 'search_results',
  SEARCH_RESULTS_EMPTY = 'search_results_empty',
}

export type RetailMediaCustomPageNamesType =
  | RetailMediaPageNames.SEARCH_RESULTS
  | RetailMediaPageNames.ADD_TO_CART
  | RetailMediaPageNames.MPLP
  | RetailMediaPageNames.LIST_PAGINATION;

export type RetailMediaPredefinedPageNamesType =
  | RetailMediaPageNames.ACCOUNT_CREATION_END
  | RetailMediaPageNames.ACCOUNT_CREATION_START
  | RetailMediaPageNames.CART
  | RetailMediaPageNames.CONTENT
  | RetailMediaPageNames.HOME
  | RetailMediaPageNames.LIST
  | RetailMediaPageNames.LOGIN
  | RetailMediaPageNames.NOT_FOUND
  | RetailMediaPageNames.POST_PAYMENT
  | RetailMediaPageNames.PRODUCT
  | RetailMediaPageNames.PURCHASE_BEGIN
  | RetailMediaPageNames.SEARCH_RESULTS_EMPTY;

export type RetailMediaPageNamesType =
  | RetailMediaPredefinedPageNamesType
  | RetailMediaCustomPageNamesType;

export interface T2sParamsInterface {
  /* aID field should be provided when pID correspond to a list category page. */
  aID?: string;
  /* bS field should be provided when pID correspond to a cart page. 'order_amount_without_delivery' */
  bS?: string;
  /* cID is the customerID which differs for each shop and for production or staging */
  cID?: string;
  /**
   * iID?: is the productID and provides the product context, context is required for the following page types:
   * product page, shopping cart, list / category, post-payment, search results (not empty);
   * More generally, all pages where one or several products are displayed.
   * cart and checkout format is: 'order_products_id_1|order_products_id_2'
   */
  iID?: string;
  /* kW: is the search term used in the search results page */
  kW?: string;
  /* offerID: is the offerID of the current product in the following format 'order_products_id_1:offerID' */
  offerID?: string;
  /* oID field should be provided when pID correspond to a post payment page. */
  oID?: string;
  /* pageNbr: is the page number of the current list page as string */
  pageNbr?: string;
  /**
   * pID corresponds to the pageID
   * target2sell need an ID to specify which page is currently visited,
   * the following Page ID ranges are predefined:
   * -------
   * home: [1000, 1199],
   * product: [1200, 1399],
   * list: [1400, 1599],
   * cart: [1600, 1799],
   * content: [2200, 2399],
   * postPayment: [2400, 2599],
   * login: [2600, 2799],
   * account_creation_start: [2800, 2999],
   * account_creation_end: [3000, 3199],
   * purchase_begin: [3200, 3399],
   * search_results_empty: [3400, 3599],
   * not_found: [3600, 3799],
   * -----
   * custom pageId's to extend the predefined page and other logic is allowed
   */
  pID?: string;
  /* priceL: is the price of the product in the following format 'order_products_id_1_priceXQty|order_products_id_2_priceXQty' */
  priceL?: string;
  /* eN: is the action by default 'view' */
  eN?: string;
  /* hasRankOption: by default true  */
  hasRankOption?: boolean;
  /* uEM: hashed user email if logged in */
  uEM?: string;
  /* qTE: is the quantity of the product in the following format 'order_products_id_1_quantity|order_products_id_2_quantity' */
  qTE?: string;
}
