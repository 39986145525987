import React, { useCallback, useContext } from 'react';
import {
  FavouritesContextInterface,
  ProductItemType,
} from 'modules/favourites/pages/Favourites/Favourites.types';
import { FavouritesContext } from 'modules/favourites/pages/Favourites/Favourites.state';
import ListSelectItem from 'modules/favourites/components/ListSelectItem';
import CreateListLink from 'modules/favourites/components/CreateListLink';
import styles from 'modules/favourites/components/ListSelectModal/ListSelectModal.scss';

interface ListSelectModalProps {
  addFavourite: (products: ProductItemType[], favouriteListId: string) => void;
  selectedProduct: ProductItemType;
  toggleModal: () => void;
}

const ListSelectModal = ({ addFavourite, selectedProduct, toggleModal }: ListSelectModalProps) => {
  const { favouritesState } = useContext<FavouritesContextInterface>(FavouritesContext);

  const handleItemClick = useCallback(
    (products: ProductItemType[], listId: string) => {
      toggleModal();
      addFavourite(products, listId);
    },
    [toggleModal, addFavourite],
  );

  const renderLists = () =>
    favouritesState.favourites.lists.map((list) => (
      <ListSelectItem
        favouriteList={list}
        key={list.name}
        handleItemClick={handleItemClick}
        selectedProduct={selectedProduct}
      />
    ));

  return (
    <div data-testid="favourites.listSelectModal">
      <CreateListLink selectedProduct={selectedProduct} handlePostSubmit={toggleModal} />
      <div className={styles.listWrapper}>{renderLists()}</div>
    </div>
  );
};

export default ListSelectModal;
