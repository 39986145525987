export const CATEGORYPAGE_PREFIX = 'categoryPage-';
export const PRODUCT_PAGE_PREFIX = '/p/';
export const SEARCH_PREFIX = '/s/';
export const SEARCH_URL = `${SEARCH_PREFIX}?s=`;
export const CHANGE_AMOUNT_TIMEOUT = 1000;
export const DEBOUNCING_TIME = 200;
export const DEBOUNCING_TIME_SHORT = 100;
export const TIMEOUT_REMOVE_ENTRY = 4000;
export const TRBO_CAMPAIGNS = 'trboCampaigns';
export const ORDER = 'ORDER';
export const RESERVATION = 'RESERVATION';

// errors that can happen anywhere
// this error can be used for arbitrary forms - if we get an error, but it's not a validation error (those are handled by the form fields)
export const GLOBAL_FORM_ERROR = 'form.errors.globalError';

export const ENV_PORTS = {
  lz: {
    at: 8100,
    ch: 8106,
    cz: 8103,
    de: 8101,
    hr: 8105,
    hu: 8108,
    ro: 8110,
    rs: 8107,
    se: 8102,
    si: 8104,
    sk: 8109,
  },
  mbx: {
    at: 8300,
    cz: 8301,
    hu: 8303,
    sk: 8302,
  },
  mmx: {
    at: 8200,
    de: 8201,
    hu: 8203,
    hr: 8205,
    ro: 8204,
    si: 8202,
    pl: 8206,
    ch: 8207,
    rs: 8208,
  },
  poco: {
    de: 8401,
  },
};
