import React, { useState, useCallback, useMemo } from 'react';
import { Button, Content, Modal, Link as HydraLink } from '@xxxlgroup/hydra-ui-components';
import { Field, Form } from 'components/Form';
import useMessage from 'components/Message/useMessage';
import { LOCALIZATION, LIST_MODAL_TYPES } from 'modules/favourites/constants';
import track from 'react-tracking';
import { useTracking } from 'utils/tracking/hooks';
import { tagComponent } from 'utils/tracking/tracking';
import { INPUT_LENGTH, validateMaxLength } from 'utils/validation';

import styles from 'modules/favourites/components/ListModal/ListModal.scss';

interface ListModalProps {
  setIsModalOpen: (isModalOpen: false) => void;
  handlePostSubmit?: (listName: string) => void;
  preFillListName?: string;
  type: LIST_MODAL_TYPES;
  isModalOpen: boolean;
  loading: boolean;
}

const ListModal = (props: ListModalProps) => {
  const { handlePostSubmit, type, preFillListName, isModalOpen, setIsModalOpen, loading } = props;
  const tracking = useTracking(props, 'ListModal');

  const [listName, setListName] = useState<any>(preFillListName);

  const [
    createNewListModalTitle,
    deleteListModalTitle,
    updateListModalTitle,
    emptyListModalTitle,
    createNewListInputListName,
    createListModalSubmitButton,
    updateListModalButton,
    deleteListModalButton,
    deleteListModalText,
    emptyListModalText,
    emptyListModalButton,
    listModalCancelButton,
    closeButton,
  ] = useMessage(
    [
      LOCALIZATION.favouritesCreateNewListModalTitle,
      LOCALIZATION.favouritesDeleteListModalTitle,
      LOCALIZATION.favouritesUpdateListModalTitle,
      LOCALIZATION.favouritesEmptyListModalTitle,
      LOCALIZATION.favouritesCreateNewListModalInputListName,
      LOCALIZATION.favouritesCreateNewListModalSubmitButton,
      LOCALIZATION.favouritesUpdateListModalButton,
      LOCALIZATION.favouritesDeleteListModalButton,
      LOCALIZATION.favouritesDeleteListModalText,
      LOCALIZATION.favouritesEmptyListModalText,
      LOCALIZATION.favouritesEmptyListModalButton,
      LOCALIZATION.favouritesListModalCancelButton,
      LOCALIZATION.closeButton,
    ],
    { listName },
  );

  const handleSubmit = async () => {
    handlePostSubmit && handlePostSubmit(listName);
  };

  const titleObject = useMemo(
    () => ({
      [LIST_MODAL_TYPES.CREATE_LIST]: createNewListModalTitle,
      [LIST_MODAL_TYPES.UPDATE_LIST]: updateListModalTitle,
      [LIST_MODAL_TYPES.DELETE_LIST]: deleteListModalTitle,
      [LIST_MODAL_TYPES.EMPTY_LIST]: emptyListModalTitle,
      [LIST_MODAL_TYPES.PRINT_LIST]: '',
    }),
    [createNewListModalTitle, deleteListModalTitle, emptyListModalTitle, updateListModalTitle],
  );

  const buttonTextObject = useMemo(
    () => ({
      [LIST_MODAL_TYPES.CREATE_LIST]: createListModalSubmitButton,
      [LIST_MODAL_TYPES.UPDATE_LIST]: updateListModalButton,
      [LIST_MODAL_TYPES.DELETE_LIST]: deleteListModalButton,
      [LIST_MODAL_TYPES.EMPTY_LIST]: emptyListModalButton,
      [LIST_MODAL_TYPES.PRINT_LIST]: '',
    }),
    [
      createListModalSubmitButton,
      deleteListModalButton,
      emptyListModalButton,
      updateListModalButton,
    ],
  );

  const handleClick = useCallback(
    (event: React.MouseEvent) => {
      tracking(event);
    },
    [tracking],
  );

  const handleCancelClick = useCallback(
    (event: React.MouseEvent) => {
      tracking(event);
      setIsModalOpen(false);
    },
    [setIsModalOpen, tracking],
  );

  const handleOnCloseModal = useCallback(
    (event: React.MouseEvent | KeyboardEvent) => {
      tracking(event);
      setIsModalOpen(false);
    },
    [setIsModalOpen, tracking],
  );

  const preFillValues = useCallback(() => ({ listName: preFillListName }), [preFillListName]);

  if (isModalOpen) {
    return (
      <Modal
        heading={titleObject[type]}
        i18n={{ close: closeButton }}
        onClose={handleOnCloseModal}
        variant="sidebar"
      >
        <Form
          data-purpose={`favourites.list.form.${type}`}
          initialValues={preFillValues()}
          onSubmit={handleSubmit}
        >
          {type === LIST_MODAL_TYPES.DELETE_LIST || type === LIST_MODAL_TYPES.EMPTY_LIST ? (
            <Content
              tag="p"
              size="sm"
              content={
                type === LIST_MODAL_TYPES.DELETE_LIST ? deleteListModalText : emptyListModalText
              }
            />
          ) : (
            <Field
              name="listName"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setListName(event.target.value)
              }
              placeholderCode={createNewListInputListName}
              labelCode={createNewListInputListName}
              validators={[validateMaxLength(INPUT_LENGTH.SINGLE_LINE)]}
              isRequired
            />
          )}
          <Button
            layout="block"
            type="submit"
            loading={loading}
            data-testid="modal.submitButton"
            data-purpose={`modal.submitButton.${type}`}
            className={styles.submitButton}
            onClick={handleClick}
          >
            {buttonTextObject[type]}
          </Button>
          <HydraLink
            layout="block"
            onClick={handleCancelClick}
            data-purpose={`modal.cancelButton.${type}`}
            className={styles.cancelButton}
            theme="dark"
            typography="small"
            underline
          >
            {listModalCancelButton}
          </HydraLink>
        </Form>
      </Modal>
    );
  }

  return null;
};

export default track(tagComponent('ListModal'))(ListModal);
