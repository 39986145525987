import useFeatureFlagsEnabled from 'hooks/useFeatureFlagsEnabled';
import { useExperiment } from '@xxxlgroup/xxxl-a-b-testing';

export const useNewHeader = () => {
  const enableNewHeaderFinally = useFeatureFlagsEnabled('poseidon.header.cleanVersionIsFinal');
  const enableNewHeaderWithABTest = useFeatureFlagsEnabled('poseidon.header.cleanVersion');
  const { variant, variants } = useExperiment('newHeader');

  return enableNewHeaderFinally || (enableNewHeaderWithABTest && variant === variants.enabled);
};
