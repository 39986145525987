import React, { useCallback, useContext, useState, useEffect, useMemo } from 'react';

import track from 'react-tracking';
import { useTracking } from 'utils/tracking/hooks';
import { tagComponent } from 'utils/tracking/tracking';

import { Button, Modal } from '@xxxlgroup/hydra-ui-components';
import { filledHeart, like } from '@xxxlgroup/hydra-icons';
import { LOCALIZATION } from 'modules/favourites/constants';
import { pseudoIcon } from '@xxxlgroup/hydra-utils/icon/pseudoIcon';

import classnames from 'classnames';
import useMessage from 'components/Message/useMessage';
import useAddFavourites from 'modules/favourites/hooks/useAddFavourites';
import useRemoveFavourites from 'modules/favourites/hooks/useRemoveFavourites';
import useFavouritesLoginModal from 'modules/favourites/hooks/useFavouritesLoginModal';
import { FavouritesContext } from 'modules/favourites/pages/Favourites/Favourites.state';
import { findProductInFavourites } from 'modules/favourites/utils/favourites';
import { FavouritesContextInterface } from 'modules/favourites/pages/Favourites/Favourites.types';
import ListSelectModal from 'modules/favourites/components/ListSelectModal';
import LoginModal from 'modules/favourites/components/LoginModal';

import styles from 'modules/favourites/components/ToggleFavouriteButton/ToggleFavouriteButton.scss';

type ToggleFavouriteButtonProps = {
  code: string;
  className?: string;
  isAnimated?: boolean;
};

const ToggleFavouriteButton = (props: ToggleFavouriteButtonProps) => {
  const { code, className, isAnimated } = props;
  const [iconLikeStyle, iconLikeClassName] = pseudoIcon(like);
  const [iconFilledHeartStyle, iconFilledHeartClassName] = pseudoIcon(filledHeart, 'after');
  const { favouritesState } = useContext<FavouritesContextInterface>(FavouritesContext);
  const { favourites } = favouritesState;
  const { addFavourite, loading: addLoading, isAnonymous } = useAddFavourites();

  const { removeFavourite, loading: removeLoading } = useRemoveFavourites();
  const loadingIcon = addLoading || removeLoading;
  const tracking = useTracking(props, 'ToggleFavouriteButton');
  const [favouriteButtonAriaLabel, favouritesListModalTitle] = useMessage([
    'product.details.addtowishlist',
    LOCALIZATION.favouritesListModalTitle,
  ]);

  const [isListsModalOpen, setIsListsModalOpen] = useState(false);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [isFavouriteChecked, setIsFavouriteChecked] = useState(false);
  const [isGoToSignup, setIsGoToSignup] = useState(false);
  const [isLoggedin, setIsLoggedin] = useState(false);
  const { isLoginModalEnabled, goToSignUpPage, setLoginModalSession } = useFavouritesLoginModal();

  const product = useMemo(() => ({ code, type: 'Product' }), [code]);

  useEffect(() => {
    if (!favourites.loading) {
      const favEntry = findProductInFavourites(favourites, code);
      setIsFavouriteChecked(!!favEntry);
    }
  }, [code, favourites, loadingIcon]);

  useEffect(() => {
    if (isGoToSignup && !addLoading) {
      goToSignUpPage();
    }
  }, [addLoading, goToSignUpPage, isGoToSignup]);

  useEffect(() => {
    if (isLoggedin && !isAnonymous) {
      addFavourite([product]);
    }
  }, [addFavourite, isAnonymous, isLoggedin, product]);

  const toggleListsModal = useCallback(() => {
    setIsListsModalOpen(!isListsModalOpen);
  }, [isListsModalOpen, setIsListsModalOpen]);

  const addProductToFavourite = useCallback(() => {
    if (isLoginModalEnabled) {
      setIsLoginModalOpen(true);
      setLoginModalSession();
    } else {
      addFavourite([product]);
    }
  }, [addFavourite, isLoginModalEnabled, product, setLoginModalSession]);

  const closeLoginModal = useCallback(
    (goToSignup = false, loggedin = false) => {
      setIsLoginModalOpen(false);
      setLoginModalSession();
      loggedin ? setIsLoggedin(true) : addFavourite([product]);
      if (goToSignup) {
        setIsGoToSignup(true);
      }
    },
    [addFavourite, product, setLoginModalSession],
  );

  const handleToggleFavourites = useCallback(
    (event: React.MouseEvent) => {
      const listsLength = favouritesState?.favourites?.lists?.length;
      tracking(event, {
        id: product.code,
        state: isFavouriteChecked ? 'uncheck.favourites.button' : 'check.favourites.button',
      });

      if (isFavouriteChecked) {
        removeFavourite(product.code);
      } else {
        listsLength > 1 ? toggleListsModal() : addProductToFavourite();
      }
    },
    [
      favouritesState?.favourites?.lists?.length,
      tracking,
      isFavouriteChecked,
      removeFavourite,
      product.code,
      toggleListsModal,
      addProductToFavourite,
    ],
  );

  const propsButton = isFavouriteChecked
    ? { testId: 'favourites.checked', iconStyle: styles.checkedIcon, iconAfter: filledHeart }
    : { testId: 'favourites.unchecked', iconStyle: styles.uncheckedIcon, iconAfter: like };

  const iconStateClassName = isFavouriteChecked ? iconFilledHeartClassName : iconLikeClassName;
  const iconStyle = isFavouriteChecked ? iconFilledHeartStyle : iconLikeStyle;

  return (
    <>
      {isLoginModalOpen && (
        <LoginModal onClose={(goToSignup, loggedin) => closeLoginModal(goToSignup, loggedin)} />
      )}
      {isListsModalOpen && (
        <Modal heading={favouritesListModalTitle} onClose={toggleListsModal} variant="sidebar">
          <ListSelectModal
            selectedProduct={product}
            addFavourite={addFavourite}
            toggleModal={toggleListsModal}
          />
        </Modal>
      )}
      {isAnimated ? (
        <button
          aria-label={favouriteButtonAriaLabel}
          className={classnames(styles.toggleFavouriteButton, className, iconStateClassName, {
            [styles.animate]: isFavouriteChecked,
          })}
          type="button"
          aria-pressed={isFavouriteChecked}
          data-purpose="button.toggle.favourites"
          onClick={handleToggleFavourites}
          style={iconStyle}
        />
      ) : (
        <Button
          className={classnames(styles.favourite, className, propsButton.iconStyle)}
          aria-pressed={isFavouriteChecked}
          data-purpose="button.toggle.favourites"
          data-testid={propsButton.testId}
          onClick={handleToggleFavourites}
          ariaLabel={favouriteButtonAriaLabel}
          variant="primary"
          layout="block"
          iconSize="large"
          glyphAfter={propsButton.iconAfter}
        />
      )}
    </>
  );
};

export default track(tagComponent('ToggleFavouriteButton'))(ToggleFavouriteButton);
