export enum LOCALIZATION {
  favouritesPageTitle = 'favourites.page.title',
  favouritesEmptyPageHeading = 'favourites.emptyPage.heading',
  favouritesEmptyPageNonLoggedInDescription = 'favourites.emptyPage.nonLoggedIn.description',
  favouritesEmptyPageLoggedInDescription = 'favourites.emptyPage.loggedIn.description',
  favouritesEmptyPageListsOrganizerText = 'favourites.emptyPage.lists.organizer.text',
  favouritesEmptyPageUserLoginText = 'favourites.emptyPage.user.login.text', // This translation key is also used in the empty care page.
  favouritesDiscoveryButtonText = 'favourites.discovery.button.text',
  favouritesBackToShoppingLinkText = 'favourites.backToShopping.link.text',
  favouritesCreateNewListInfoHeading = 'favourites.createNewList.info.heading',
  favouritesCreateNewListInfoText = 'favourites.createNewList.info.text',
  favouritesCreateNewListButtonText = 'favourites.createNewList.button.text',
  favouritesCreateNewListModalTitle = 'favourites.createNewList.modal.title',
  favouritesCreateNewListModalInputListName = 'favourites.createNewList.modal.input.listName',
  favouritesCreateNewListModalSubmitButton = 'favourites.createNewList.modal.submit.button',
  favouritesListModalCancelButton = 'favourites.list.modal.cancel.button',
  favouritesCreateNewListSuccessMessage = 'favourites.createNewList.success.message',
  favouritesCreateNewListErrorMessage = 'favourites.createNewList.error.message',
  closeButton = 'wxs.modal.closeButton.ariaLabel',
  favouritesListsPageTitle = 'favourites.lists.overview.title',
  favouritesFlyoutEmptyPageContentTitle = 'favourites.flyout.emptyPage.content.title',
  favouritesFlyoutEmptyPageContentText = 'favourites.flyout.emptyPage.content.text',
  favouritesFlyoutEmptyPageLogInText = 'favourites.flyout.emptyPage.login.text',
  favouritesHeadingPage = 'favourites.heading.page',
  favouritesNumberArticles = 'favourites.number.articles',
  favouritesDefaultListName = 'favourites.default.list.name',
  favouritesEmptyListPageHeading = 'favourites.emptyListPage.heading',
  favouritesEmptyListPageDescription = 'favourites.emptyListPage.description',
  favouritesListModalTitle = 'favourites.listModal.title',
  favouritesProductAddedToList = 'favourites.listSelect.productAdded',
  favouritesFlyoutEmptyPageDescription = 'favourites.flyout.emptyPage.description',
  favouritesDeleteListModalTitle = 'favourites.deleteList.modal.title',
  favouritesDeleteListModalText = 'favourites.deleteList.modal.text',
  favouritesDeleteListModalButton = 'favourites.deleteList.modal.button',
  favouritesDeleteListSuccessMessage = 'favourites.deleteList.success.message',
  favouritesDeleteListErrorMessage = 'favourites.deleteList.error.message',
  favouritesUpdateListModalTitle = 'favourites.updateList.modal.title',
  favouritesUpdateListModalButton = 'favourites.updateList.modal.button',
  favouritesUpdateListSuccessMessage = 'favourites.updateList.success.message',
  favouritesUpdateListErrorMessage = 'favourites.updateList.error.message',
  favouritesEmptyListModalTitle = 'favourites.emptyList.modal.title',
  favouritesEmptyListModalText = 'favourites.emptyList.modal.text',
  favouritesEmptyListModalButton = 'favourites.emptyList.modal.button',
  favouritesEmptyListSuccessMessage = 'favourites.emptyList.success.message',
  favouritesDefaultListLoggedOutPromptTitle = 'favourites.defaultList.loggedOut.prompt.title',
  favouritesDefaultListLoggedOutPromptText = 'favourites.defaultList.loggedOut.prompt.text',
  favouritesDefaultListLoggedInPromptText = 'favourites.defaultList.loggedIn.prompt.text',
  favouritesListOptionsTitle = 'favourites.list.options.title',
  favouritesListOptionsRename = 'favourites.list.options.rename',
  favouritesListOptionsPrint = 'favourites.list.options.print',
  favouritesListOptionsDelete = 'favourites.list.options.delete',
  favouritesListOptionsDefaultListDelete = 'favourites.list.options.defaultList.delete',
  favouritesGetDataError = 'favourites.getData.error',
  favouritesAddEntryError = 'favourites.addEntry.error',
  favouritesRemoveEntryError = 'favourites.removeEntry.error',
  favouritesAddEntrySuccess = 'favourites.addEntry.success',
  favouritesRemoveEntrySuccess = 'favourites.removeEntry.success',
  favouritesEntryMoveToCartSuccess = 'favourites.product.addToCart.success',
  favouritesEntryMoveToCartError = 'wxs.checkout.product.add.error',
  favouritesEntryNoLongerAvailable = 'favourites.product.no.longer.available',
  favouritesLoginTitle = 'favourites.loginModal.title',
  favouritesLoginAnonymousDescription = 'favourites.loginModal.anonymous.description',
  favouritesLoginLoginLinkText = 'favourites.loginModal.login.linkText',
  favouritesLoginAnonymousLinkText = 'favourites.loginModal.anonymous.linkText',
  favouritesLoginSignuptitle = 'favourites.loginModal.signup.title',
  favouritesLoginSignupButton = 'favourites.loginModal.signup.button',
}

export enum ACTION_TYPES {
  ADD_ENTRY = 'ADD_ENTRY',
  REMOVE_ENTRY = 'REMOVE_ENTRY',
  CREATE_LIST = 'CREATE_LIST',
  UPDATE_LIST = 'UPDATE_LIST',
  DELETE_LIST = 'DELETE_LIST',
  EMPTY_LIST = 'EMPTY_LIST',
  GET_DATA = 'GET_DATA',
  MOVE_ENTRY_TO_CART = 'MOVE_ENTRY_TO_CART',
}

export enum NOTIFICATION_TYPES {
  ERROR = 'error',
  SUCCESS = 'success',
}

export enum LIST_MODAL_TYPES {
  CREATE_LIST = 'CREATE_LIST',
  UPDATE_LIST = 'UPDATE_LIST',
  DELETE_LIST = 'DELETE_LIST',
  EMPTY_LIST = 'EMPTY_LIST',
  PRINT_LIST = 'PRINT_LIST',
}

export const IS_LOGIN_MODAL_OPENED = 'favouritesLoginModalTriggered';
