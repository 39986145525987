import { useEffect, useState } from 'react';
import { local } from '@xxxlgroup/hydra-utils/storage';
import { initialStateService } from 'pages/ProductDetail/Product.state';

export default function useProductService(productCode: string) {
  const [service, setService] = useState(initialStateService);

  useEffect(() => {
    const selectedServices = local.getItem(`services-${productCode}`);
    selectedServices && setService({ serviceCode: selectedServices });
  }, [productCode, setService]);

  return {
    service,
    setService,
  };
}
