import { createContext } from 'react';

export interface AuthContextInterface {
  isAnonymous: boolean;
  isPoseidonLoginEnabled: boolean | null;
  login: (userid: string, password: string) => void;
  logout: () => void;
}

const AuthContext = createContext<AuthContextInterface>({
  isAnonymous: true,
  isPoseidonLoginEnabled: false,
  login: (userid, password) => Promise.resolve([]),
  logout: () => Promise.resolve([]),
});

export default AuthContext;
