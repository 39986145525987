import { useState } from 'react';

import useFeatureFlags from 'hooks/useFeatureFlags';
import { initialStateUnitBasedCalculation } from 'pages/ProductDetail/Product.state';
import type { UnitBasedCalculationExtended } from 'pages/ProductDetail/ProductDetails.types';
import type { Product } from 'graphql-types/generated/types-generated';

export default function useUnitBasedCalculationSidebar(priceData: Product['priceData']) {
  const [unitBasedCalculation, setUnitBasedCalculation] = useState<UnitBasedCalculationExtended>(
    initialStateUnitBasedCalculation,
  );

  const ppuCalculationFormFF = useFeatureFlags('poseidon.price.per.unit.calculation.form');

  const showUnitCalculator = !!(ppuCalculationFormFF && priceData?.productUnitDiffersToPriceUnit);

  return {
    state: {
      unitBasedCalculation,
      showUnitCalculator,
    },
    stateSetters: {
      setUnitBasedCalculation,
    },
  };
}
