import { useCallback } from 'react';
import { useLazyQuery } from '@apollo/client';
import { GET_PRODUCTS_QUERY } from 'modules/favourites/queries/Favourites.query';

const useGetFavouriteProductsQuery = () => {
  const [getFavouriteProductsQuery, { data, loading, error }] = useLazyQuery(GET_PRODUCTS_QUERY);
  const getFavouriteProducts = useCallback(
    (codes: string) => {
      if (codes) {
        getFavouriteProductsQuery({
          variables: { codes },
        });
      }
    },
    [getFavouriteProductsQuery],
  );

  return { getFavouriteProducts, data, loading, error };
};

export default useGetFavouriteProductsQuery;
