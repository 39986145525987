import { useCallback, useState } from 'react';
import useGetFavouriteProductsQuery from 'modules/favourites/hooks/useGetFavouriteProductsQuery';
import { ProductItemType, ProductType } from 'modules/favourites/pages/Favourites/Favourites.types';

import { getCodesFromEntries, addProductToLocal } from 'modules/favourites/utils/favourites';

export interface ProductAddDataType {
  products: ProductType[];
}

const useAddFavouriteToClient = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { getFavouriteProducts, data, loading, error } = useGetFavouriteProductsQuery();

  const addFavouriteToClient = useCallback(
    async (products: ProductItemType[]) => {
      setIsLoading(true);
      const product = products[0];
      addProductToLocal(product);
      const codes = getCodesFromEntries();
      await getFavouriteProducts(codes);
      setIsLoading(false);
    },
    [getFavouriteProducts],
  );

  return {
    addFavouriteToClient,
    dataAddToClient: data,
    loadingAddToClient: isLoading || loading,
    errorAddToClient: error,
  };
};

export default useAddFavouriteToClient;
