import { gql } from '@apollo/client';

export const LOGIN_MUTATION = gql`
  mutation login($userCredentials: UserBaseLoginInput!) {
    login(userCredentials: $userCredentials) {
      isAnonymous
      orderCartMerged
    }
  }
`;
