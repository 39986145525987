import { useCallback } from 'react';
import { useLazyQuery } from '@apollo/client';

import { GET_FAVOURITE_LISTS_QUERY } from 'modules/favourites/queries/Favourites.query';

const useGetFavouritesFromServer = () => {
  const [getFavouritesQuery, { data, loading, error }] = useLazyQuery(GET_FAVOURITE_LISTS_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });
  const getFavouriteFromServer = useCallback(() => {
    getFavouritesQuery();
  }, [getFavouritesQuery]);

  return {
    getFavouriteFromServer,
    dataGetFromServer: data,
    errorGetFromServer: error,
    loadingGetFromServer: loading,
  };
};

export default useGetFavouritesFromServer;
