import { useContext, useMemo } from 'react';

import getFeatureFlag from 'components/FeatureFlag/getFeatureFlags';
import ApplicationDataContext from 'pages/App/components/ApplicationDataProvider/ApplicationDataContext';

/**
 * Checks the status of one or more FeatureFlags.
 * @param {String | [String]} featureFlag One or more FeatureFlags.
 * @returns An Array where each value represents the enabled state of the corresponding FeatureFlag. The Array is empty if the FeatureFlags aren't fully loaded.
 */
const useFeatureFlags = (featureFlag: string | Array<string>) => {
  const { features, isLoading } = useContext(ApplicationDataContext);

  return useMemo(
    () => (isLoading ? [] : getFeatureFlag(features || [], featureFlag)),
    [isLoading, features, featureFlag],
  );
};

export default useFeatureFlags;
