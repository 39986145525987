import { useCallback, useContext } from 'react';
import { useMutation } from '@apollo/client';
import { findProductInLists } from 'modules/favourites/utils/favourites';
import { FavouritesContext } from 'modules/favourites/pages/Favourites/Favourites.state';
import { REMOVE_FAVOURITE_ITEM_MUTATION } from 'modules/favourites/queries/Favourites.mutation';
import { FavouritesContextInterface } from 'modules/favourites/pages/Favourites/Favourites.types';

const useRemoveFavouriteFromServer = () => {
  const { favouritesState } = useContext<FavouritesContextInterface>(FavouritesContext);
  const { lists } = favouritesState.favourites;
  const [removeFavouriteItem, { data, error, loading }] = useMutation(
    REMOVE_FAVOURITE_ITEM_MUTATION,
  );

  const removeFavouriteFromServer = useCallback(
    async (code: string) => {
      const { listId, id } = findProductInLists(lists, code) || { listId: null, id: null };
      if (listId && id) {
        await removeFavouriteItem({
          fetchPolicy: 'no-cache',
          variables: {
            favouriteListId: listId,
            favouriteItemId: id,
          },
        });
      }
    },
    [lists, removeFavouriteItem],
  );

  return {
    removeFavouriteFromServer,
    dataRemoveFromServer: data,
    errorRemoveFromServer: error,
    loadingRemoveFromServer: loading,
  };
};

export default useRemoveFavouriteFromServer;
