import React, { useMemo, useState, useContext, useEffect, useCallback, ReactNode } from 'react';
import { useHistory } from 'react-router-dom';
import { generatePath } from 'react-router';
import { MutationTuple, useMutation } from '@apollo/client';
import { GraphQLError } from 'graphql';
import { isArrayEmpty } from '@xxxlgroup/hydra-utils/common';
import { getCookie } from 'utils/cookie';
import useRoutes from 'hooks/useRoutes';
import useFeatureFlagsEnabled from 'hooks/useFeatureFlagsEnabled';
import { LOGIN_MUTATION } from 'modules/customer-authentication/Login.mutation';
import { LOGOUT_MUTATION } from 'modules/customer-authentication/Logout.mutation';
import Context, { AuthContextInterface } from 'modules/customer-authentication/AuthContext.context';

export const AuthContext = Context;

const userChangeEventName = 'onUserChange';

const getUserAnonymousFromCookies = () => {
  const result = getCookie('user_anonymous');
  return typeof result === 'string' ? JSON.parse(result.toLowerCase()) : true;
};

interface MutationVariablesType {
  userCredentials: {
    email: string;
    userid: string;
    password: string;
  };
}

const authOperation = async (
  mutationOperation: MutationTuple<any, any>,
  variables?: MutationVariablesType,
) => {
  let data = null;
  let errors = null;
  let exception = null;
  try {
    ({ data, errors } = await mutationOperation[0]({
      fetchPolicy: 'no-cache',
      variables,
    }));
  } catch (error) {
    exception = error;
  }

  return {
    data,
    exception,
    errors: isArrayEmpty(errors as GraphQLError[]) ? null : errors,
  };
};

export const fireOnUserChangeEvent = (invalidAuthToken: boolean) => {
  const event = new CustomEvent(userChangeEventName, { detail: { invalidAuthToken } });
  document.dispatchEvent(event);
};

const isPageInCustomerAccount = (nameCustomerAccount: string, pathname: string) => {
  const pathnameParts = pathname?.split('/');
  if (pathnameParts?.length > 1) {
    const firstPartOfPath = pathnameParts[1];
    return nameCustomerAccount === firstPartOfPath;
  }
  return false;
};

export const useAuth = () => useContext(AuthContext);

interface AuthContextProviderProps {
  children: ReactNode;
}

export const AuthContextProvider = ({ children }: AuthContextProviderProps) => {
  const {
    customerAuthentication: { signIn },
    customerAccount,
  } = useRoutes();
  const [isAnonymous, setIsAnonymous] = useState(getUserAnonymousFromCookies());
  const loginMutation = useMutation(LOGIN_MUTATION);
  const logoutMutation = useMutation(LOGOUT_MUTATION);
  const [, { client }] = logoutMutation;
  const isPoseidonLoginEnabled = useFeatureFlagsEnabled('poseidon.login.enabled');
  const history = useHistory();

  const login = useCallback(
    async (userid: string, password: string) => {
      const { data, errors, exception } = await authOperation(loginMutation, {
        userCredentials: {
          email: userid,
          userid,
          password,
        },
      });
      return {
        data,
        errors,
        exception,
      };
    },
    [loginMutation],
  );

  const logout = useCallback(async () => {
    await client.clearStore();
    const { data, errors, exception } = await authOperation(logoutMutation);
    return {
      data,
      errors,
      exception,
    };
  }, [client, logoutMutation]);

  const updateAnonymous = useCallback(() => {
    const oldIsAnon = isPoseidonLoginEnabled ? isAnonymous : true;
    const newIsAnon = isPoseidonLoginEnabled ? getUserAnonymousFromCookies() : true;
    const changed = oldIsAnon !== newIsAnon;

    if (isPoseidonLoginEnabled && changed) {
      setIsAnonymous(newIsAnon);
    }

    return {
      changed,
      oldIsAnon,
      newIsAnon,
    };
  }, [isAnonymous, isPoseidonLoginEnabled]);

  useEffect(() => {
    updateAnonymous();
    return history.listen?.(updateAnonymous);
  }, [history, updateAnonymous]);

  useEffect(() => {
    const onUserChanged = (event: CustomEvent) => {
      const {
        detail: { invalidAuthToken },
      } = event;
      const { newIsAnon, oldIsAnon } = updateAnonymous();

      if (
        invalidAuthToken &&
        !oldIsAnon &&
        newIsAnon &&
        isPageInCustomerAccount(customerAccount.name, history.location.pathname)
      ) {
        history.push(generatePath(signIn.root), { invalidAuthToken });
      }
    };

    // see open TS issue https://github.com/Microsoft/TypeScript/issues/28357
    document.addEventListener(userChangeEventName, onUserChanged as EventListener);

    return () => {
      document.removeEventListener(userChangeEventName, onUserChanged as EventListener);
    };
  }, [customerAccount.name, history, signIn.root, updateAnonymous]);

  const contextValue: AuthContextInterface = useMemo(
    () => ({
      isAnonymous: isPoseidonLoginEnabled ? isAnonymous : true,
      isPoseidonLoginEnabled,
      login,
      logout,
    }),
    [isAnonymous, isPoseidonLoginEnabled, login, logout],
  );

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};
