import { useCallback, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import useOnPageLoad from 'hooks/useOnPageLoad';
import { routesDefault } from 'Providers/IntlProvider/routesDir/common';

const useFocusHeadingAfterPageLoad = () => {
  const history = useHistory();

  const firstHeadingShouldFocus = useRef(true);
  const prevPathName = useRef(null);

  useEffect(
    () =>
      history.listen(({ pathname, hash }) => {
        if (pathname !== prevPathName?.current && !hash) {
          prevPathName.current = pathname;
          firstHeadingShouldFocus.current = true;
        }
      }),
    [history],
  );

  const selectFirstHeading = useCallback(() => {
    const heading = document.querySelector('h1');
    if (
      heading &&
      firstHeadingShouldFocus.current &&
      document.location.pathname !== routesDefault.homePage
    ) {
      heading.setAttribute('tabindex', '-1');
      heading.style.outline = 'none';
      heading.focus({
        preventScroll: true,
      });
      firstHeadingShouldFocus.current = false;
    }
  }, []);

  useOnPageLoad(selectFirstHeading);
};

export default useFocusHeadingAfterPageLoad;
