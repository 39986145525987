import PropTypes from 'prop-types';

export const addressTypes = {
  billingAddress: 'billingAddress',
  shippingAddress: 'shippingAddress',
};

export const AddressDataType = PropTypes.shape({
  id: PropTypes.string,
  gender: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  titleCode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  firstname: PropTypes.string,
  lastname: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  streetname: PropTypes.string,
  streetnumber: PropTypes.string,
  floor: PropTypes.string,
  lift: PropTypes.bool,
  postalCode: PropTypes.string,
  town: PropTypes.string,
  country: PropTypes.string,
  addition1: PropTypes.string,
  companyName: PropTypes.string,
  attn: PropTypes.string,
  salesTaxNumbers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      number: PropTypes.string,
    }),
  ),
  advert: PropTypes.bool,
  defaultBilling: PropTypes.bool,
  defaultMarketing: PropTypes.bool,
  defaultShipping: PropTypes.bool,
  created: PropTypes.string,
});
