import { useCallback } from 'react';
import { useAuth } from 'modules/customer-authentication/AuthContext';
import { useHistory } from 'react-router-dom';
import useRoutes from 'hooks/useRoutes';
import { session } from '@xxxlgroup/hydra-utils/storage';
import { IS_LOGIN_MODAL_OPENED } from 'modules/favourites/constants';

const useFavouritesLoginModal = () => {
  const history = useHistory();
  const { customerAuthentication } = useRoutes();

  const { isAnonymous, isPoseidonLoginEnabled } = useAuth();

  const goToSignUpPage = useCallback(() => {
    history.push(`/${customerAuthentication.signUp.name}`);
  }, [customerAuthentication.signUp.name, history]);

  const setLoginModalSession = useCallback(() => {
    session.setItem(IS_LOGIN_MODAL_OPENED, true);
  }, []);

  const getLoginModalSession = () => session.getItem(IS_LOGIN_MODAL_OPENED);

  const isLoginModalEnabled = isAnonymous && isPoseidonLoginEnabled && !getLoginModalSession();
  return { isLoginModalEnabled, goToSignUpPage, setLoginModalSession, getLoginModalSession };
};

export default useFavouritesLoginModal;
