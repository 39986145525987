import React, { type FunctionComponent, type ReactNode } from 'react';
import Error404 from 'components/ErrorHandler/components/Error404';
import FeatureFlag, { type FeatureFlagProps } from 'components/FeatureFlag';
import { ERROR_REASONS } from 'components/ErrorHandler/ErrorHandler';

type featureFlagObjectType = {
  code: string;
  fallback?: ReactNode;
  condition?: FeatureFlagProps['condition'];
};

export type featureFlagType = string | string[] | featureFlagObjectType;

const withFeatureFlag = (Component: FunctionComponent, featureFlag: featureFlagType) => {
  const nonObjectFeatureFlag = Array.isArray(featureFlag) || typeof featureFlag === 'string';
  const featureFlagObject = nonObjectFeatureFlag ? { code: featureFlag } : featureFlag;
  const {
    code,
    fallback = <Error404 errorReason={ERROR_REASONS[404].flagNotFound} />,
    condition = 'every',
  } = featureFlagObject as featureFlagObjectType;

  return (props: any) => (
    <FeatureFlag code={code} fallback={fallback} condition={condition} isDeferWhileLoading>
      <Component {...props} />
    </FeatureFlag>
  );
};

export default withFeatureFlag;
