import { useCallback, useMemo } from 'react';
import useFeatureFlagsEnabled from 'hooks/useFeatureFlagsEnabled';
import { useAuth } from 'modules/customer-authentication/AuthContext';
import useGetFavouritesFromClient from 'modules/favourites/hooks/client/useGetFavouritesFromClient';
import useGetFavouritesFromServer from 'modules/favourites/hooks/server/useGetFavouritesFromServer';

const useGetFavourites = () => {
  const { isAnonymous } = useAuth();
  const isFavouritesEnabled = useFeatureFlagsEnabled('poseidon.favourites');
  const { getFavouriteFromClient, dataGetFromClient, errorGetFromClient, loadingGetFromClient } =
    useGetFavouritesFromClient();
  const { getFavouriteFromServer, dataGetFromServer, errorGetFromServer, loadingGetFromServer } =
    useGetFavouritesFromServer();
  const errors = errorGetFromClient || errorGetFromServer;
  const loading = loadingGetFromClient || loadingGetFromServer;
  const data = useMemo(
    () => (isAnonymous ? dataGetFromClient?.getProducts : dataGetFromServer?.getFavouriteLists),
    [dataGetFromClient?.getProducts, dataGetFromServer?.getFavouriteLists, isAnonymous],
  );

  const getFavourites = useCallback(() => {
    if (isFavouritesEnabled) {
      isAnonymous ? getFavouriteFromClient() : getFavouriteFromServer();
    }
  }, [getFavouriteFromClient, getFavouriteFromServer, isAnonymous, isFavouritesEnabled]);

  return {
    getFavourites,
    data,
    loading,
    errors,
  };
};

export default useGetFavourites;
